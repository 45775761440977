import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { Button, Col, Container, Form, FormGroup, FormLabel, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
/*import './Login.css';*/
import BCBigImg from "../../../Assets/Images/bcbigimg.png"
import BankClarityLogo from "../../../Assets/Images/VistraTheme/BankClarityBrand.png"
import PoweredByLogo from "../../../Assets/Images/Powered by BC black.png"
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import styles from "./SendResetRequest.module.css"
import userManager from '../../../Util/AuthService';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import MenuItem from '@mui/material/MenuItem';
import DialogActions from '@mui/material/DialogActions';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';

const ResetPasswordConfirm = () => {

    //TODO
    const loginAPI = process.env.REACT_APP_APIBaseURL + "/" + process.env.REACT_APP_APIName + 'Authentication/ResetPassword';
    const navigate = useNavigate();
    const [formJSON, setFormJSON] = React.useState(null);
    const [showMismatch, setShowMismatch] = React.useState(false);
    const [showFormatting, setShowFormatting] = React.useState(false);

    useEffect(() => {

    }, []);


    const performRequest = async (event) => {
        event.preventDefault();
        const formElement = document.querySelector('#RequestForm');
        const btnPointer = document.querySelector('#Request-btn');
        const formData = new FormData(formElement);
        const formDataJSON = Object.fromEntries(formData);
        const passwordRegex =  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{6,}$/;
        //charcters re not symbols?? allowed

        if (formDataJSON.Password != formDataJSON.NewPassword) {
            setShowMismatch(true);
            setShowFormatting(false);
        }
        else if (!passwordRegex.test(formDataJSON.Password)) {
            setShowFormatting(true);
            setShowMismatch(false);
        }
        else {

            // get these from url
            const searchParams = new URLSearchParams(window.location.search);

            formDataJSON.Tenant = searchParams.get('tenant');
            formDataJSON.ResetToken = searchParams.get('reset');

            const response = await axios.post(loginAPI, formDataJSON).then(res => {
                localStorage.setItem('token-success', "Password Changed")
                returnToLogin();
            })
                .catch(error => {
                    localStorage.setItem('token-error', "A problem has occured during password change")
                    returnToLogin();

                });
        }
    }

    function returnToLogin() {
        navigate('/');
    }

    return (
        <React.Fragment>
            <div className={styles.loginContainer}>
                <Container className={styles.RequestForm}>
                    <Row>
                        <Col md={{ span: 12 }} >
                            <div onClick={returnToLogin}><ArrowBackIcon className={styles.secondBackButton}></ArrowBackIcon> Return </div>

                            <div className="form-group mt-3">
                                <div className={styles.loginLogo}>
                                    <div className="BankClarityLoginLogo"></div>

                                </div>
                                <Form id="RequestForm" onSubmit={performRequest} className={styles.loginFormContainer + " " + styles.loginWrapCard}>

                                    <FormGroup className="mb-3">
                                     
                                       

                                        {<div className={styles.passwordGuide}>
                                            Passwords must contain at least 6 characters and a combination of letters and numbers, uppercase and lowercase (no special characters)
                                        </div>}

                                        {showMismatch && <div className={styles.passwordMatch}> Passwords dont match </div>}

                                        {showFormatting && <div className={styles.passwordMatch}>
                                            Password format wrong
                                        </div>}

                                        <FormLabel htmlFor={'reset-password'}>Password</FormLabel>
                                        <input type={'password'} className="form-control" id={'login-Password'} name="Password" required />

                                        <FormLabel htmlFor={'reset-password'}>Re-enter Password</FormLabel>
                                        <input type={'password'} className="form-control" id={'login-NewPassword'} name="NewPassword" required />


                                    </FormGroup>
                                    <div className={styles.loginButtonHolder}>

                                        <Button type="submit" className={styles.loginButton + ' btn-success mt-2'} id="Request-btn">Change Password</Button>

                                    </div>



                                </Form>
                            </div>
                        </Col>

                    </Row>

                </Container>
            </div>
        </React.Fragment>
    );
}


export default ResetPasswordConfirm;