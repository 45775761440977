/// <reference path="../../../elements/tables/tableexpandablebuttonrow.js" />
import { Button, Card } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import '../../../Styles/Shared/content-page.css';
import TableExpandableButtonRow from '../../../Elements/Tables/TableExpandableButtonRow.js';
import TableExpandableDefaultContent from '../../../Elements/Tables/TableExpandableDefaultContent.js';
import APIService from '../../../Util/APIService.js'
import moment from "moment";
import { DataGridPro, useGridApiRef } from "@mui/x-data-grid-pro";
import { useNavigate, Outlet, BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import styles from "./PaymentsList.module.css"
import { useMediaQuery } from '@mui/material';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import FileUpload from "../../../Elements/File Upload/FileUploadAdvanced.js";

function PaymentsList(props) {
    //State declarations
    const [payments, setPayments] = useState([]);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [dataContainer, setDataContainer] = useState({ columns: [], rows: [] });
    const [loading, setLoading] = useState(false);
    const [gridWidth, setGridWidth] = useState(window.innerWidth);
    const [columnList, setColumnList] = useState([]);
    const [openImport, setOpenImport] = React.useState(false);
    const [payerFilter, setPayerFilter] = useState([]);
    const isMobile = useMediaQuery('(max-width:900px)');
    const [newPaymentInfo, setNewPaymentInfo] = useState({
        paymentFiles: []
    });

    const updateUploadedFiles = (files) =>
        setNewPaymentInfo({ ...newPaymentInfo, paymentFiles: files });

    //Settings
    const navigate = useNavigate();
    moment.locale('en-GB');
    const showColumns = windowWidth > 900;
    const showColumnsSm = windowWidth > 768;
    const apiRef = useGridApiRef();


    //Effect
    useEffect(() => {
        async function fetchPayments() {
            setLoading(true);
            try {
                const result = await APIService.get("payments/GetPayments").then(res => {

                    var dataCon = {
                        rows: res.data
                    }
                    setPayments(res.data);
                    setDataContainer(dataCon);
                    // get filter list
                    var payers = [];
                    for (var i = 0; i < res.data.length; i++) {
                        if (payers.indexOf(res.data[i].Client) == -1) {
                            payers.push(res.data[i].Client);
                        }
                    }
                    if (isMobile) {
                        const columns = [
                            { field: '__detail_panel_toggle__', flex: 0.5, minWidth: 10 },
                            { field: 'QuestionnaireId', flex: 0, minWidth: 75 },

                            { field: 'Client', headerName: 'Payer', flex: 2, minWidth: 75, type: "singleSelect", valueOptions: payers },
                            { field: 'PayerAccountName', headerName: 'Payer Account', flex: 2, minWidth: 75 },
                            { field: 'PayeeName', headerName: 'Payee', flex: 2, minWidth: 75 },
                            { field: 'PaymentDescription', headerName: 'Description', flex: 2, minWidth: 75 },
                            //{ field: 'PaymentReasonDescription', headerName: 'Reason', flex: 2, minWidth: 75 },
                            { field: 'PaymentCurrencyIsoCode', headerName: 'CCY', flex: 0.5, minWidth: 50 },
                            {
                                field: 'PaymentAmount', type: 'number', headerName: 'Amount', flex: 1.5, minWidth: 90, valueFormatter: (params) => {
                                    return Number(params.value).toLocaleString(process.env.REACT_APP_CurrencyFormat, { minimumFractionDigits: params.api.getRow(params.id).PayeeCurrencyDecimalPlaces })
                                }
                            },
                            { field: 'PaymentMethod', headerName: 'Method', flex: 1, minWidth: 75 },
                            { field: 'Inbox', headerName: 'Inbox', flex: 1.5, minWidth: 75 },
                            { field: 'Source', headerName: 'Source', flex: 1.5, minWidth: 75 },
                            { field: 'PaymentDate', type: 'date', headerName: 'Date', flex: 1, valueFormatter: (params) => moment(params.value).format("DD/MM/YYYY"), minWidth: 75 },
                        ];
                        setColumnList(columns);
                    }
                    else {
                        const columns = [
                            { field: '__detail_panel_toggle__', flex: 0.5, minWidth: 10 },
                            { field: 'QuestionnaireId', flex: 0, minWidth: 75 },
                            { field: 'Client', headerName: 'Payer', flex: 2, minWidth: 75, type: "singleSelect", valueOptions: payers },
                            { field: 'PayerAccountName', headerName: 'Payer Account', flex: 2, minWidth: 75 },
                            { field: 'PayeeName', headerName: 'Payee', flex: 2, minWidth: 75 },
                            { field: 'PaymentDescription', headerName: 'Description', flex: 2, minWidth: 75 },
                            //{ field: 'PaymentReasonDescription', headerName: 'Reason', flex: 2, minWidth: 75 },
                            { field: 'PaymentCurrencyIsoCode', headerName: 'CCY', flex: 0.5, minWidth: 50 },
                            {
                                field: 'PaymentAmount', type: 'number', headerName: 'Amount', flex: 1.5, minWidth: 90, valueFormatter: (params) => {
                                    return Number(params.value).toLocaleString(process.env.REACT_APP_CurrencyFormat, { minimumFractionDigits: params.api.getRow(params.id).PayeeCurrencyDecimalPlaces })
                                }
                            },
                            { field: 'PaymentMethod', headerName: 'Method', flex: 1, minWidth: 75 },
                            { field: 'Source', headerName: 'Source', flex: 1.5, minWidth: 75 },
                            { field: 'Inbox', headerName: 'Inbox', flex: 1.5, minWidth: 75 },
                            { field: 'PaymentDate', type: 'date', headerName: 'Date', flex: 1.5, valueFormatter: (params) => moment(params.value).format("DD/MM/YYYY"), minWidth: 75 },


                        ];
                        setColumnList(columns);
                    }

                    setLoading(false);
                })
                    .catch(error => {
                        navigate('/ErrorPage', { state: error.message });
                    });
            }
            catch (error) {
                console.error('Error fetching data:', error);
            }
        }

        function handleResize() {
            setWindowWidth(window.innerWidth);
        }

        window.addEventListener('resize', handleResize);



        fetchPayments();

        // remove listners to avoid data leak
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    //Data
    const ButtonRowData = [{ route: "/PaymentView", description: "View" }];
    function getExpandableContent(row) {
        var retVar = [{ label: "Payee", value: row.PayeeName },
        { label: "Payer", value: row.Client },
        { label: "Payer Account", value: row.PayerAccountName },
        { label: "Payment date", value: moment(row.PaymentDate).format("DD/MM/YYYY") },
        { label: "Reason", value: row.PaymentReasonDescription },
        { label: "CCY", value: row.PaymentCurrencyIsoCode },
            { label: "Amount", value: Number(row.PaymentAmount).toLocaleString(process.env.REACT_APP_CurrencyFormat, { minimumFractionDigits: row.PayeeCurrencyDecimalPlaces }) },
            { label: "Method", value: row.PaymentMethod },
            { label: "Inbox", value: row.Inbox },
            { label: "Description", value: row.PaymentDescription },
            { label: "Source", value: row.Source },
        ]
        return retVar;
    }

    const handleFileSelect = (file) => {
        // Perform the file upload logic here
        handleClickCloseImport();
    };

    const handleClickOpenImport = () => {
        setOpenImport(true);
    };

    const handleClickCloseImport = () => {
        setOpenImport(false);
    };

    const handleCloseImportComplete = () => {
        /*   rejectPayments(selectedRows, rejectReason)*/
        setOpenImport(false);
    };

    const onRowClick = (params) => {
        apiRef.current.toggleDetailPanel(params.id);
    }

    const fileInput = React.useRef();

    const handleOpenFilterPanel = () => {
        //default is client
        apiRef.current.showFilterPanel("Client");
    };

    // View
    return (
        <div>
            <Dialog open={openImport} onClose={handleClickCloseImport}>
                <DialogTitle>Import Payment</DialogTitle>
                <DialogContent>
                    COMING SOON
                    <FileUpload onFileSelect={handleFileSelect} onClose={handleCloseImportComplete} />
                </DialogContent>
            </Dialog>
            <div className="nav-action-bar">
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="/">
                        Home
                    </Link>
                    <Link
                        underline="none"
                        color="inherit"
                    >
                        Payment List
                    </Link>
                </Breadcrumbs>
                <div className="action-button-position">
                    <Button className="authorisation-button" variant="primary" onClick={handleClickOpenImport} type="success" loading={loading.toString()}>
                        Import
                    </Button>
                </div>
            </div>

            <Card className="list-page-padding">
                <TuneOutlinedIcon className={styles.filterIcon} onClick={handleOpenFilterPanel}></TuneOutlinedIcon>
                <Card.Body>
                    <div style={{ width: "100%" }}>
                        <DataGridPro
                            width="100%"
                            pageSizeOptions={[15, 25, 50, 100, 250]}
                            columnVisibilityModel={{
                                QuestionnaireId: false,
                                Client: showColumns,
                                PayeeName: showColumns,
                                PaymentReasonDescription: true,
                                PaymentTypeDescription: showColumns,
                                PaymentDate: showColumns,
                                Source: false,
                                PaymentAmount: true,
                                PaymentCurrencyIsoCode: showColumnsSm,
                                PaymentMethod: false,
                                Inbox: showColumns,
                                PayerAccountName: showColumns,
                                __detail_panel_toggle__: showColumns

                            }}
                            initialState={{
                                pagination: { ...dataContainer.initialState?.pagination, paginationModel: { pageSize: 25 } }

                            }}
                            getRowId={(row) => row.QuestionnaireId}
                            autoHeight={true}
                            disableRowSelectionOnClick
                            density="comfortable"
                            disableColumnSelector={true}
                            loading={loading}
                            apiRef={apiRef}
                            onRowClick={onRowClick}
                            pagination
                            {...gridWidth && { autoWidth: gridWidth }}
                            getRowHeight={() => 'auto'}
                            columns={columnList}
                            getDetailPanelContent={({ row }) =>
                                <div className={styles.tableContainerBorder}>
                                    <TableExpandableButtonRow data={{ dataContainer: ButtonRowData, IDContainer: row.QuestionnaireId }} />
                                    <TableExpandableDefaultContent data={getExpandableContent(row)} /></div>}
                            getDetailPanelHeight={({ row }) => 'auto'} // Height based on the content.
                            rows={payments} />
                    </div>
                </Card.Body>
            </Card>
        </div>
    )
}

export default PaymentsList;