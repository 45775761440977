import { createSlice } from '@reduxjs/toolkit';

const infoMessageSlice = createSlice({
    name: 'infoMessage',
    initialState: { infoMessage: '' },
    reducers: {
        setInfo(state, action) {
            state.infoMessage = action.payload;
        },
        clearInfo(state) {
            state.infoMessage = '';
        },
    },
});

export const { setInfo, clearInfo } = infoMessageSlice.actions;
export default infoMessageSlice.reducer;