import userManager from '../../Util/AuthService';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { Button, Col, Container, Form, FormGroup, FormLabel, Row } from "react-bootstrap";
import DialogContent from '@mui/material/DialogContent';
import MenuItem from '@mui/material/MenuItem';
import DialogActions from '@mui/material/DialogActions';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';

const SignInOIDCCallback = () => {
    const navigate = useNavigate();
    const [tenantDialogOpen, setTenantDialogOpen] = useState(false);
    const [tenantSelectedOption, setTenantSelectedOption] = useState('');
    const [tenantOptions, setTenantOptions] = useState([]);

    const instance = axios.create({
        baseURL: process.env.REACT_APP_APIBaseURL,
        timeout: 50000,
        headers: {
            'Content-Type': 'application/json',
        }
    });

    instance.interceptors.request.use(
        config => {
            const token = localStorage.getItem('user-token');
            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
            }
            return config;
        },
        error => Promise.reject(error)
    );

    const handleTenantConfirm = () => {
        setTenantDialogOpen(false);
        tenantPassLogin();

    };

    const handleTenantOptionChange = (event) => {
        setTenantSelectedOption(event.target.value);
    };

    const tenantPassLogin = async () => {
        try {
            return instance.post(process.env.REACT_APP_APIBaseURL + "/" + process.env.REACT_APP_APIName + 'Authentication/AuthenticateForSSO', tenantSelectedOption).then((response) => {
                const token = response.data;
                if (token == null || token == "") {
                    return;
                }
                if (typeof token === 'string') {
                    localStorage.setItem('user-token', token);
                    localStorage.setItem('passedSSO', "passed");
                    setTimeout(() => {
                        window.location.reload(false);
                        navigate('/');
                    }, 500);
                }
            }).catch((error) => {
                localStorage.clear();
                localStorage.setItem('token-error', "Login failed, Please check details and try again")
                window.location.reload(false);

                navigate('/');
                return;

            });
        }
        catch (err) {
            localStorage.clear();
            localStorage.setItem('token-error', "Login failed, Please check details and try again")
            window.location.reload(false);
            navigate('/');
            return;
        }
    };


    useEffect(() => {
        const handleCallback = async () => {
            const urlParams = new URLSearchParams(window.location.search);
            const code = urlParams.get('code');
            const error = urlParams.get('error');
            if (code) {
                // Code exists, proceed with token exchange
                const passedSSO = localStorage.getItem('passedSSO');
                if (!passedSSO || passedSSO === 'undefined') {

                    try {
                        await userManager.signinRedirectCallback();
                        const user = await userManager.getUser();

                        

                        // Redirect to the desired route or handle successful authentication
                        /*                window.location.href = '/';*/

                        //needs to pass out token to be validated at api.

                        localStorage.clear();
                        localStorage.setItem('user-token', user.id_token);

                        try {
                            return instance.post(process.env.REACT_APP_APIBaseURL + "/" + process.env.REACT_APP_APIName + 'Authentication/AuthenticateForSSO').then((response) => {
                                const token = response.data;
                                if (token == null || token == "") {
                                    return;
                                }
                                if (typeof token === 'string') {
                                    localStorage.clear();
                                    localStorage.setItem('user-token', token);
                                    localStorage.setItem('passedSSO', "passed");
                                    setTimeout(() => {
                                        window.location.reload(false);
                                        navigate('/');
                                    }, 1000);
                                }
                                else {
                                    var tenantOptionsList = [];
                                    for (var i = 0; i < token.length; i++) {
                                        tenantOptionsList.push({ label: token[i].Description, value: token[i].Name })
                                    }
                                    setTenantOptions(tenantOptionsList);
                                    setTenantDialogOpen(true);
                                }



                            }).catch((error) => {

                                localStorage.clear();
                                localStorage.setItem('token-error', "Login failed, Please check details and try again")
                                window.location.reload(false);

                                navigate('/');
                                return;

                            });
                        }
                        catch (err) {

                            localStorage.clear();
                            localStorage.setItem('token-error', "Login failed, Please check details and try again")
                            window.location.reload(false);

                            navigate('/');
                            return;
                        }
                    } catch (e) {

                        // Handle signinRedirectCallback error
                        localStorage.clear();
                        navigate('/');
                        //console.error('Error in signinRedirectCallback:', e);
                    }
                }
                else {
                    setTimeout(() => {
                        navigate('/');
                    }, 100);
                }
            } else if (error) {

                localStorage.clear();
                navigate('/');
                // Handle authentication error
                //console.error('Authentication error:', error);
            }
        };
       
        handleCallback();
    }, []);
    return (<div>
        <Dialog fullWidth={true} maxWidth={"lg"} open={tenantDialogOpen}>
        <DialogTitle>Select an Tenant</DialogTitle>
        <DialogContent>
            <Select
                displayEmpty
                value={tenantSelectedOption}
                onChange={handleTenantOptionChange}
            >
                <MenuItem disabled value="">
                    <em> Please select </em>
                </MenuItem>
                {tenantOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleTenantConfirm}>Confirm</Button>
        </DialogActions>
    </Dialog></div>);
};

export default SignInOIDCCallback;