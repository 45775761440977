
import React, { useRef, useState } from 'react';
import { createRoot } from 'react-router-dom';
import '../../Styles/Shared/content-page.css';
import styles from './Containers/Notifications.module.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import GeneralNotificationContainer from './Containers/GeneralNotificationContainer';
import PaymentNotificationContainer from './Containers/PaymentNotificationContainer';
import UserNotificationContainer from './Containers/UserNotificationContainer';
import ProcessingContainer from './Containers/ProcessingContainer';
import Tabs from '@mui/material/Tabs';
import PropTypes from 'prop-types';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TabPanel from '../../Elements/Tabs/TabPanel'
import Badge from '@mui/material/Badge';
import Divider from '@mui/material/Divider';

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const Notifications = () => {

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs variant="scrollable" scrollButtons="auto" value={value} onChange={handleChange} aria-label="basic tabs">
                    <Tab label="General" {...a11yProps(0)} />
                   
                    <Tab label="User" {...a11yProps(1)} />
                    <Tab label="Payment" {...a11yProps(2)} />

                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                <GeneralNotificationContainer />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <UserNotificationContainer />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <PaymentNotificationContainer />
            </TabPanel>  
        </Box>
    )
}
export default Notifications;
