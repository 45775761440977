import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, FormGroup, FormLabel, Row } from "react-bootstrap";
import SinglePagePDFViewer from "./SinglePagePDF";


export default function PDFDownload(props) {
    const [showViewer, setShowViewer] = useState(null);
    const [allowPDFdownload, setAllowPDFdownload] = useState(false);

    const viewPDF = () => {
        setShowViewer(true);
    }

    const onCancel = () => {
        setAllowPDFdownload(false);
        setShowViewer(false);
    }

    const downloadPdf = () => {
        // Create a blob from the base64 string
        console.log(props)
        const base64Content = props.pdf.split(',')[1];
        const byteCharacters = atob(base64Content);

        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: props.pdf.split(',')[0].split(';')[0].replace("data:", "") });
        //msword

        // Create a URL for the blob
        const blobUrl = URL.createObjectURL(blob);

        // Create an invisible <a> element to trigger the download
        const a = document.createElement('a');
        a.href = blobUrl;
        a.download = props.title; // Specify the file name
        a.style.display = 'none';

        // Trigger the download
        document.body.appendChild(a);
        a.click();

        // Clean up
        document.body.removeChild(a);
        URL.revokeObjectURL(blobUrl);
    };

    return (   
        <div>
            <Button disabled={!props.pdf.split(',')[0].split(';')[0].toLowerCase().includes("pdf")} onClick={viewPDF}>View</Button>
            <Button onClick={downloadPdf}>Download</Button>
            {showViewer && (
                <>
                    <SinglePagePDFViewer pdf={props.pdf} onCancel={onCancel} />
                </>
            )}

        </div>
    );
};
