import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { Button, Col, Container, Form, FormGroup, FormLabel, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
/*import './Login.css';*/
import BCBigImg from "../../../Assets/Images/bcbigimg.png"
import BankClarityLogo from "../../../Assets/Images/VistraTheme/BankClarityBrand.png"
import PoweredByLogo from "../../../Assets/Images/Powered by BC black.png"
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import styles from "./SendResetRequest.module.css"
import userManager from '../../../Util/AuthService';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import MenuItem from '@mui/material/MenuItem';
import DialogActions from '@mui/material/DialogActions';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';

const SendResetRequest = () => {

    //TODO
    const loginAPI = process.env.REACT_APP_APIBaseURL + "/" + process.env.REACT_APP_APIName + 'Authentication/RequestPasswordReset';
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(true);
    const [showTenantSelection, setShowTenantSelection] = React.useState(false);
    const [formJSON, setFormJSON] = React.useState(null);
    const [tenantSelectedOption, setTenantSelectedOption] = useState('');
    const [tenantOptions, setTenantOptions] = useState([]);

    useEffect(() => {
        const response = axios.get(process.env.REACT_APP_APIBaseURL + "/" + process.env.REACT_APP_APIName + 'Authentication/AvailableTenants').then(res => {
            var tenantOptionsList = [];
            for (var i = 0; i < res.data.length; i++) {
                tenantOptionsList.push({ label: res.data[i].Description, value: res.data[i].Name })
            }
            setTenantOptions(tenantOptionsList)
            if (tenantOptionsList.length < 2 && tenantOptionsList.length > 0) {
                setTenantSelectedOption(tenantOptionsList[0].value);
                setShowTenantSelection(false);
            } if (tenantOptionsList.length > 1) {
                setShowTenantSelection(true);
            }
        })
            .catch(error => {


            });;

    }, []);


    const performRequest = async (event) => {
        event.preventDefault();
        const formElement = document.querySelector('#RequestForm');
        const btnPointer = document.querySelector('#Request-btn');
        const formData = new FormData(formElement);
        const formDataJSON = Object.fromEntries(formData);

        formDataJSON.Tenant = "";
        formDataJSON.Code = "";
        formDataJSON.Password = "";
        if (tenantSelectedOption != "" && tenantSelectedOption != null) {
            formDataJSON.Tenant = tenantSelectedOption;
        }

        const response = await axios.post(loginAPI, formDataJSON).then(res => {
            localStorage.setItem('token-success', "If password reset username is valid, a password reset email has been sent")
            returnToLogin();
        })
            .catch(error => {
                localStorage.setItem('token-error', "A problem has occured during password reset")
                returnToLogin();

            });
    }

    const handleTenantOptionChange = (event) => {
        setTenantSelectedOption(event.target.value);
    };

    function returnToLogin() {
        navigate('/');
    }

    return (
        <React.Fragment>
            <div className={styles.loginContainer}>
            <Container className={styles.RequestForm}>
                <Row>
                        <Col md={{ span: 12 }} >
                            <div onClick={returnToLogin}><ArrowBackIcon className={styles.secondBackButton}></ArrowBackIcon> Return </div>

                                        <div className="form-group mt-3">
                                <div className={styles.loginLogo}>
                                    <div className="BankClarityLoginLogo"></div>

                                </div>
                                    <Form id="RequestForm" onSubmit={performRequest} className={styles.loginFormContainer + " " + styles.loginWrapCard}>

                                    <FormGroup className="mb-3">
                                        {showTenantSelection && <div><FormLabel htmlFor={'reset-tenant'}>Tenant</FormLabel>
                                        <Select
                                            displayEmpty
                                            value={tenantSelectedOption}
                                            onChange={handleTenantOptionChange}
                                            className={styles.tenantSelection}
                                        >
                                            <MenuItem disabled value="">
                                                <em> Select Tenant </em>
                                            </MenuItem>
                                            {tenantOptions.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        </div>
                                        }

                                        <FormLabel htmlFor={'reset-username'}>User Name</FormLabel>
                                        <input type={'text'} className="form-control" id={'login-UserName'} name="UserName" required />


                                       

                                            </FormGroup>
                                            <div className={styles.loginButtonHolder}>
                                               
                                                <Button type="submit" className={styles.loginButton + ' btn-success mt-2'} id="Request-btn">Request Password Reset</Button>

                                            </div>

                                           

                                        </Form>
                        </div>
                    </Col>

                </Row>

                </Container>
            </div>
        </React.Fragment>
    );
}

export default SendResetRequest;