import { useState } from 'react';
import styles from "./OTPasscode.module.css"
import { Button, Col, Container, Form, FormGroup, FormLabel, Row } from "react-bootstrap";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import BankClarityLogo from "../../Assets/Images/VistraTheme/BankClarityBrand.png"
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import React, { useEffect } from "react";

const OTPasscode = ({ onOk, onCancel, visible }) => {
    const [inputValue, setInputValue] = useState('');

    useEffect(() => {
    }, [visible]);

    const handleOk = () => {
      
        const formElement2 = document.querySelector('#pinForm');
        const formData2 = new FormData(formElement2);
        const btnPointer = document.querySelector('#OTPConfirmOperation-btn');
        btnPointer.innerHTML = 'Please wait..';
        btnPointer.setAttribute('disabled', true);
        var pins = "";
        for (const value of formData2.values()) {
            pins = pins + value;
        }
        if (pins == "") {
            btnPointer.innerHTML = 'Confirm';
            btnPointer.removeAttribute('disabled', false);
            alert("please enter code")
        }
        else {
            onOk(pins)
        }
    };

    const handleCancel = () => {
        onCancel();
        setInputValue('');
    };

    return (
        <Modal
            open={visible}
            onOk={handleOk}
            onCancel={handleCancel}
            okText="Reject"
            cancelText="Cancel"
        >
            <Container className={styles.AuthOTPForm}>
                <Row>
                    <Col md={{ span: 12 }} >
                        <div className={styles.AuthFormContent}>

                            <div className="form-group mt-3">
                                <div className={styles.loginLogo}>
                                    <img src={BankClarityLogo} height="30px" width="auto"></img> {/*this needs to change to just icon without text if upright on phone*/}
                                </div>
                                <Form id="pinForm" method="POST" className={styles.loginFormContainer + " " + styles.loginWrapCard}>
                                    <div className={styles.pinCodeInstructions}>Enter one time passcode </div>
                                    <FormGroup className="mb-3">
                                        <Box sx={{ width: '100%' }}>
                                            <input className={styles.pinCode} type="tel" name="pincode-1" maxlength="1" pattern="[\d]*" tabindex="1" placeholder="" autocomplete="off"></input>
                                            <input className={styles.pinCode} type="tel" name="pincode-2" maxlength="1" pattern="[\d]*" tabindex="1" placeholder="" autocomplete="off"></input>
                                            <input className={styles.pinCode} type="tel" name="pincode-3" maxlength="1" pattern="[\d]*" tabindex="1" placeholder="" autocomplete="off"></input>
                                            <input className={styles.pinCode} type="tel" name="pincode-4" maxlength="1" pattern="[\d]*" tabindex="1" placeholder="" autocomplete="off"></input>
                                            <input className={styles.pinCode} type="tel" name="pincode-5" maxlength="1" pattern="[\d]*" tabindex="1" placeholder="" autocomplete="off"></input>
                                            <input className={styles.pinCode} type="tel" name="pincode-6" maxlength="1" pattern="[\d]*" tabindex="1" placeholder="" autocomplete="off"></input>

                                        </Box>
                                    </FormGroup>
                                    <div className={styles.pinButtonContainer}>
                                        <Button onClick={handleOk} type="Button" className={"btn-success mt-2 " + styles.OTPButton + " " + styles.pinButton} id="OTPConfirmOperation-btn">Confirm</Button>
                                        <Button onClick={handleCancel} type="Button" className={"btn-success mt-2 " + styles.OTPButton + " " + styles.pinButton} id="OTPCancelOperation-btn">Cancel</Button>
                                    </div>

                                </Form> </div>
                        </div>
                    </Col>

                </Row>

            </Container>
        </Modal>
    );
};

export default OTPasscode;
