import React from 'react';
import '../../../Styles/Shared/content-page.css';
import BankStatementChart from './Chart/BankStatementChart.js';
import { Card } from "react-bootstrap";
import TableExpandableDefaultContent from '../../../Elements/Tables/TableExpandableDefaultContent.js';
import DetailPanel from './Detail Panel/DetailPanel.jsx';
import { DataGridPro, useGridApiRef, GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid-pro";
import { useLocation, useNavigate, Outlet, BrowserRouter as Router, Routes, Route, Link as RouterLink } from "react-router-dom";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { useRef, useState } from 'react';
import moment from "moment";
import { useEffect } from "react";
import APIService from '../../../Util/APIService.js'
import Grid from '@mui/material/Grid';
import { Button } from "react-bootstrap";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import 'moment/locale/en-gb';
import styles from "./BankStatement.module.css";
import "./Override.css";
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { useMediaQuery } from '@mui/material';
moment.locale('en-GB');

function BankAccountSearch(props) {
    //State declarations
    const dataGridProRef = useRef(null);
    const [accounts, setAccounts] = useState([]);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [dataContainer, setDataContainer] = useState({ columns: [], rows: [] });
    const [loading, setLoading] = useState(false);
    const [gridWidth, setGridWidth] = useState(window.innerWidth);
    const [columnList, setColumnList] = useState([]);
    const [openConfirm, setOpenConfirm] = React.useState(false);
    const [openReject, setOpenReject] = React.useState(false);
    const [rejectReason, setRejectReason] = React.useState("");
    const [selectedRows, setSelectedRows] = useState([]);
    const [statement, setstatement] = useState([]);
    const [payments, setPayments] = useState([]);
    const [account, setAccount] = useState([]);
    

    const [chartData, setChartData] = useState({
        height: "320px", categories: [], title: "Account Balance", series: [{
            seriesName: "Balances", seriesData: [{ x: '05/06/2014', y: 0 }]
        }]
    });
    const location = useLocation();
    const isMobile = useMediaQuery('(max-width:900px)');
    const [refreshData, setRefreshData] = useState(false);

    const [startStatementDate, setStartStatementDate] = useState(moment().subtract(1, 'years'));
    const [endStatementDate, setEndStatementDate] = useState(moment());

    //Settings
    const navigate = useNavigate();
    moment.locale('en-GB');
    const showColumns = windowWidth > 900;
    const showColumnsSm = windowWidth > 768;
    const apiRef = useGridApiRef();

    function getChartData(data, opendate, openBalance, endDate, endBalance) {
        var seriesdata = [];
        for (var i = 0; i < data.length; i++) {
            var date = data[i].Date;
            var val = data[i].Balance;
            seriesdata.push({ x: date, y: val })
        }
        seriesdata.push({ x: opendate, y: openBalance });
        seriesdata.push({ x: endDate, y: endBalance });

        seriesdata.sort((a, b) => new Date(a.x) - new Date(b.x));

        var chartData = {
            height: "320px", categories: [], title: "Account Balance", series: [{
                seriesName: "Transactions", seriesData: seriesdata
            }]
        }
        setChartData(chartData);

    }

    //Events
    const handleExport = async () => {
        // Perform your export logic here
        var parameterData ='Bank Account Export Request: ' + account.title;
        const result = await APIService.post("User/Audit",  parameterData).then(res => { });


    };

    const handleSelectionModelChange = (newSelection) => {
        setSelectedRows(newSelection);
    };

    const handleReasonChange = event => {
        setRejectReason(event.target.value);
    };

    //Effect
    useEffect(() => {
        async function fetchStatement() {
            setLoading(true);
            var parameterData = {}
            if (startStatementDate == null) {
                parameterData = { bankAccountId: location.state.itemID, startDate: moment().subtract(200, 'years'), endDate: endStatementDate.toDate() }
            }
            else {
                parameterData = { bankAccountId: location.state.itemID, startDate: startStatementDate.toDate(), endDate: endStatementDate.toDate() }
            }

            const result = await APIService.post("BankAccounts/GetStatement", parameterData).then(res => {

                const parsedDataData = res.data.Transactions.map(item => ({
                    ...item,
                    Date: moment(item.Date).toDate() // Assuming the date property is named "date"
                }));

                setstatement(res.data);

                for (var i = 0; i < parsedDataData.length; i++) {
                    var calculatedAmount = 0;
                    if (parsedDataData[i].Credit != null) {
                        calculatedAmount = calculatedAmount + parseFloat(parsedDataData[i].Credit);
                    }
                    if (parsedDataData[i].Debit != null) {
                        calculatedAmount = calculatedAmount - parseFloat(parsedDataData[i].Debit);
                    }
                    parsedDataData[i].CalculatedAmount = calculatedAmount
                }
                setPayments(parsedDataData);

                getChartData(parsedDataData, moment(res.data.Balance.StartDate).toDate(), res.data.Balance.OpeningBalance, moment(res.data.Balance.EndDate).toDate(), res.data.Balance.ClosingBalance);
                setAccount(returnDetailData(res.data.Account, res.data.Balance));
                setLoading(false);
            })
                .catch(error => {
                    navigate('/ErrorPage', { state: error.message });
                });
        }

        function handleResize() {
            setWindowWidth(window.innerWidth);
        }

        window.addEventListener('resize', handleResize);

        if (isMobile) {
            const columns = [
                { field: 'BankTransactionId', flex: 0, minWidth: 50 },
                { field: 'Credit', headerName: 'Credit', flex: 2, minWidth: 50, valueFormatter: (params) => Number(params.value).toLocaleString(process.env.REACT_APP_CurrencyFormat, { minimumFractionDigits: 2 }) },
                { field: 'Debit', headerName: 'Debit', flex: 2, minWidth: 50, valueFormatter: (params) => Number(params.value).toLocaleString(process.env.REACT_APP_CurrencyFormat, { minimumFractionDigits: 2 }) },
                { field: 'CalculatedAmount', headerName: 'Amount', flex: 2, minWidth: 50, valueFormatter: (params) => Number(params.value).toLocaleString(process.env.REACT_APP_CurrencyFormat, { minimumFractionDigits: 2 }) },
                { field: 'Date', headerName: 'Date', type: 'date', flex: 2, minWidth: 50, valueFormatter: (params) => moment(params.value).format("DD/MM/YYYY") },
                { field: 'Balance', headerName: 'Balance', flex: 1, minWidth: 50, valueFormatter: (params) => Number(params.value).toLocaleString(process.env.REACT_APP_CurrencyFormat, { minimumFractionDigits: 2 }) },
                { field: 'Narrative', headerName: 'Narrative', flex: 1, minWidth: 50 },
            ];
            setColumnList(columns);
        }
        else {
            const columns = [
                { field: 'BankTransactionId', flex: 0, minWidth: 50 },
                { field: 'Date', headerName: 'Date', type: 'date', flex: 2, minWidth: 50, valueFormatter: (params) => moment(params.value).format("DD/MM/YYYY") },
                { field: 'Narrative', headerName: 'Narrative', flex: 1, minWidth: 50 },
                { field: 'Credit', headerName: 'Credit', flex: 2, minWidth: 50, valueFormatter: (params) => Number(params.value).toLocaleString(process.env.REACT_APP_CurrencyFormat, { minimumFractionDigits: 2 }) },
                { field: 'Debit', headerName: 'Debit', flex: 2, minWidth: 50, valueFormatter: (params) => Number(params.value).toLocaleString(process.env.REACT_APP_CurrencyFormat, { minimumFractionDigits: 2 }) },
                { field: 'Balance', headerName: 'Balance', flex: 1, minWidth: 50, valueFormatter: (params) => Number(params.value).toLocaleString(process.env.REACT_APP_CurrencyFormat, { minimumFractionDigits: 2 }) },
            ];
            setColumnList(columns);
        }


        fetchStatement();
        setRefreshData(false);

    }, [refreshData]);

    const handleRefreshClick = () => {
        setRefreshData(true);
    };

    //Data

    function returnDetailData(accountDetail, balanceDetail) {
        var detail = { title: accountDetail.AccountName, detail: [], className: "bank-statement-info-height" }
        detail.detail.push({ label: "Bank", value: accountDetail.BankName, colW: 6 },
            { label: "Account Holder", value: accountDetail.AccountHolderName, colW: 6 },
            { label: "", value: "line" },
            { label: "CCY", value: accountDetail.CurrencyCode, colW: 4 },
            { label: "Opening Balance", value: Number(balanceDetail.OpeningBalance).toLocaleString(process.env.REACT_APP_CurrencyFormat, { minimumFractionDigits: 2 }), colW: 4 },
            { label: "Closing Balance", value: Number(balanceDetail.ClosingBalance).toLocaleString(process.env.REACT_APP_CurrencyFormat, { minimumFractionDigits: 2 }), colW: 4 },
            { label: "Sort Code", value: accountDetail.SortCode, colW: 4 },
            { label: "Account Number", value: accountDetail.AccountNumber, colW: 4 },
            { label: "BIC", value: accountDetail.BIC, colW: 4 })
        return detail;
    }

    function getExpandableContent(row) {
        console.log(row);
        var retVar = [
            { label: "Credit", value: Number(row.Credit).toLocaleString(process.env.REACT_APP_CurrencyFormat, { minimumFractionDigits: 2 } ) },
            { label: "Debit", value: Number(row.Debit).toLocaleString(process.env.REACT_APP_CurrencyFormat, { minimumFractionDigits: 2 }) },
            { label: "Date", value: moment(row.Date).format("DD/MM/YYYY") },
            { label: "Balance", value: Number(row.Balance).toLocaleString(process.env.REACT_APP_CurrencyFormat) },
            { label: "CCY", value: row.CurrencyIsoCode },
            { label: "Narrative", value: row.Narrative },
            { label: "Status", value: row.Status },
        ]
        return retVar;
    }

    const onRowClick = (params) => {
        apiRef.current.toggleDetailPanel(params.id);
    }

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }

    function searchBankStatementDates() {
        setLoading(true);
        handleRefreshClick();
        setLoading(false);
    }


    // View
    return (
        <div>
            <div className="nav-action-bar">
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" component={RouterLink} color="inherit" to="/BankAccountList">
                        Account List
                    </Link>
                    <Link
                        underline="none"
                        color="inherit"
                    >
                        Statement
                    </Link>
                </Breadcrumbs>
                <div className={"action-button-position pointer " + styles.datepickerTopPadding}>
                    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={'en-gb'}>
                        <DatePicker className={styles.datepickerStatementWidth} label="From"
                            value={startStatementDate}
                            onChange={(newValue) => setStartStatementDate(newValue)} />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={'en-GB'}>
                        <DatePicker className={styles.datepickerStatementWidth} label="To"
                            value={endStatementDate}
                            onChange={(newValue2) => setEndStatementDate(newValue2)} />
                    </LocalizationProvider>
                    <Button className="btn-hollow" variant="light" onClick={searchBankStatementDates} loading={loading}>
                        <SearchOutlinedIcon></SearchOutlinedIcon>
                    </Button>

                </div>
            </div>
            <Grid container spacing={2} className={styles.statementTopSpacing}>
                <Grid item md={12} lg={6}>
                    <div className={styles.detailCardPrimary}>
                        <DetailPanel data={account} />
                    </div>
                </Grid>
                <Grid item lg={6}>
                    <div className={styles.detailCardSecond}>
                        <BankStatementChart data={chartData} />
                    </div>
                </Grid>

            </Grid>

            <Card className="list-page-padding">
                <Card.Body>
                    <div style={{ width: "100%" }}>
                        <DataGridPro
                            ref={dataGridProRef}
                            width="100%"
                            pageSizeOptions={[15, 25, 50, 100, 250]}
                            columnVisibilityModel={{
                                BankTransactionId: false,
                                Credit: showColumns,
                                Debit: showColumns,
                                Date: true,
                                Balance: showColumns,
                                CCY: showColumns,
                                Narrative: showColumns,
                                Status: showColumns,


                            }}
                            initialState={{
                                pagination: { ...dataContainer.initialState?.pagination, paginationModel: { pageSize: 25 } }

                            }}
                            selectionModel={selectedRows}
 
                            onRowSelectionModelChange={handleSelectionModelChange}
                            getRowId={(row) => row.BankTransactionId}
                            autoHeight={true}
                            disableRowSelectionOnClick
                            density="comfortable"
                            loading={loading}
                            apiRef={apiRef}
                            onRowClick={onRowClick}
                            components={{
                                Toolbar: (props) => (
                                    <>
                                        <GridToolbarExport onClick={handleExport} />
                                        {/* You can add additional toolbar components here */}
                                    </>
                                ),
                            }}
                            disableColumnSelector={true}
                            pagination
                            {...gridWidth && { autoWidth: gridWidth }}
                            getRowHeight={() => 'auto'}
                            columns={columnList}
                            getDetailPanelContent={({ row }) =>
                                <div className={styles.tableContainerBorder}>
                                    {/*<TableExpandableButtonRow data={{ dataContainer: ButtonRowData, IDContainer: row.BankTransactionId }} />*/}
                                    <TableExpandableDefaultContent data={getExpandableContent(row)} /></div>}
                            getDetailPanelHeight={({ row }) => 'auto'} // Height based on the content.
                            rows={payments} />
                    </div>
                </Card.Body>
            </Card>
        </div>
    )
}

export default BankAccountSearch; 