import './TableStyles.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function TableExpandableDefaultContent(props) {
    return (
        <Row className="form-row pl-2 col-xl-12 default-content-padding">
            {props.data.map((data, idx) => (
                <Col lg={4} md={6} sm={12} className="form-group mb-0 col-sm-6 " key={idx}>
                    <label className="col-form-label font-sm">{data.label}</label>
                    <div className="form-control-plaintext pt-0">                <span className="preserveFormat">{data.value}</span>
                    </div>
                </Col>
            ))}
           
           
        </Row>
    );
}

export default TableExpandableDefaultContent;