/// <reference path="../../../elements/tables/tableexpandablebuttonrow.js" />
import { Button, Card } from "react-bootstrap";
import React, { useEffect, useState, useRef } from "react";
//import './BankAccountList.css';
import '../../../Styles/Shared/content-page.css';
import TableExpandableButtonRow from '../../../Elements/Tables/TableExpandableButtonRow.js';
import TableExpandableDefaultContent from '../../../Elements/Tables/TableExpandableDefaultContent.js';
import APIService from '../../../Util/APIService.js'
import moment from "moment";
import { DataGridPro, useGridApiRef } from "@mui/x-data-grid-pro";
import { useNavigate, Outlet, BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import styles from "./BankAccountList.module.css"
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import { useMediaQuery } from '@mui/material';

function BankAccountList(props) {
    //State declarations
    const [accounts, setAccounts] = useState([]);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [dataContainer, setDataContainer] = useState({ columns: [], rows: [] });
    const [loading, setLoading] = useState(false);
    const [gridWidth, setGridWidth] = useState(window.innerWidth);
    const [columnList, setColumnList] = useState([]);
    const [openConfirm, setOpenConfirm] = React.useState(false);
    const [openReject, setOpenReject] = React.useState(false);
    const [rejectReason, setRejectReason] = React.useState("");
    const [selectedRows, setSelectedRows] = useState([]);
    const isMobile = useMediaQuery('(max-width:900px)');

    //Settings
    const navigate = useNavigate();
    moment.locale('en-GB');
    const showColumns = windowWidth > 900;
    const showColumnsSm = windowWidth > 768;
    const apiRef = useGridApiRef();

    //Events
    const handleSelectionModelChange = (newSelection) => {
        setSelectedRows(newSelection);
    };

    const handleReasonChange = event => {
        setRejectReason(event.target.value);
    };

    //Effect
    useEffect(() => {
        async function fetchAccounts() {
            setLoading(true);
            const result = await APIService.get("BankAccounts/GetBankAccounts").then(res => {
                for (var i = 0; i < res.data.length; i++) {
                    res.data[i].AccountSummary = res.data[i].BankName + " - " + res.data[i].AccountName;
                }
                setAccounts(res.data);
                setLoading(false);
            })
                .catch(error => {
                    navigate('/ErrorPage', { state: error.message });
                });
        }

        function handleResize() {
            setWindowWidth(window.innerWidth);
        }

        window.addEventListener('resize', handleResize);

        if (isMobile) {
            const columns = [
                { field: 'BankAccountId', flex: 0, minWidth: 50 },
                { field: 'AccountHolderName', headerName: 'Account Holder', flex: 2, minWidth: 50 },
                { field: 'BankName', headerName: 'Bank', flex: 2, minWidth: 50 },
                { field: 'AccountSummary', headerName: 'Bank Account', flex: 2, minWidth: 50 },
                { field: 'AccountName', headerName: 'Account', flex: 2, minWidth: 50 },
                { field: 'IBAN', headerName: 'IBAN', flex: 1, minWidth: 50 }, // should this and acc no be combined?
                { field: 'AccountNumber', headerName: 'Account No', flex: 1, minWidth: 50 },
                { field: 'BIC', headerName: 'BIC', flex: 1, minWidth: 50 },
                { field: 'CurrencyCode', headerName: 'CCY', flex: 0.5, minWidth: 90 },
                { field: 'Balance', type: 'number', headerName: 'Balance', flex: 1.5, minWidth: 50, valueFormatter: (params) => Number(params.value).toLocaleString(process.env.CurrencyFormat, { minimumFractionDigits: 2 }) },
            ];
            setColumnList(columns);
        }
        else {
            const columns = [
                { field: 'BankAccountId', flex: 0, minWidth: 50 },
                { field: 'AccountHolderName', headerName: 'Account Holder', flex: 2, minWidth: 50 },
                { field: 'BankName', headerName: 'Bank', flex: 2, minWidth: 50 },
                { field: 'AccountName', headerName: 'Account', flex: 2, minWidth: 50 },
                { field: 'IBAN', headerName: 'IBAN', flex: 1, minWidth: 50 }, // should this and acc no be combined?
                { field: 'AccountNumber', headerName: 'Account No', flex: 1, minWidth: 50 },
                { field: 'BIC', headerName: 'BIC', flex: 1, minWidth: 50 },
                { field: 'CurrencyCode', headerName: 'CCY', flex: 0.5, minWidth: 90 },
                { field: 'Balance', type: 'number', headerName: 'Balance', flex: 1.5, minWidth: 50, valueFormatter: (params) => Number(params.value).toLocaleString(process.env.CurrencyFormat, { minimumFractionDigits: 2 }) },
            ];
            setColumnList(columns);
        }

        fetchAccounts();

        // remove listners to avoid data leak
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    //Data
    const ButtonRowData = [{ route: "/BankStatement", description: "View Statement" }];
    function getExpandableContent(row) {
        var retVar = [
            { label: "Account Holder Name", value: row.AccountHolderName },
            { label: "Account Name", value: row.AccountName },
            { label: "Bank Name", value: row.BankName },
            { label: "IBAN", value: row.IBAN },
            { label: "Account Number", value: row.AccountNumber },
            { label: "BIC", value: row.BIC },
            { label: "CCY", value: row.CurrencyCode },
            { label: "Balance", value: Number(row.Balance).toLocaleString(process.env.CurrencyFormat, { minimumFractionDigits: 2 }) }
            ]
        return retVar;
    }

    function intToYesNo(value) {
        if (value == 0) {
            return "No";
        }
        else if (value == 1) {
            return "Yes";
        }

        return "Unknown"
    }

    const onRowClick = (params) => {
        apiRef.current.toggleDetailPanel(params.id);
    }

    const handleOpenFilterPanel = () => {
        apiRef.current.showFilterPanel("BankName");
    };
  
    // View
    return (
        <div>
            <div className="nav-action-bar">
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="/">
                        Home
                    </Link>
                    <Link
                        underline="none"
                        color="inherit"
                    >
                        Account List
                    </Link>
                </Breadcrumbs>
               
            </div>
            <Card className="list-page-padding">
                <TuneOutlinedIcon className={styles.filterIcon} onClick={handleOpenFilterPanel}></TuneOutlinedIcon>
                <Card.Body>
                    <div style={{ width: "100%" }}>
                        <DataGridPro
                            width="100%"
                            pageSizeOptions={[15, 25, 50, 100, 250]}
                            columnVisibilityModel={{
                                BankAccountId: false,
                                AccountName: showColumns,
                                AccountHolderName: showColumns,
                                AccountNumber: showColumns,
                                BankName: showColumns,
                                IBAN: showColumns,
                                BIC: showColumns,
                                PaymentAmount: true,
                                CurrencyCode: true,
                                Unclarified: showColumns,
                                IsExternalyBookkept: showColumns,
                                __detail_panel_toggle__: showColumns
                            }}
                            initialState={{
                                pagination: { ...dataContainer.initialState?.pagination, paginationModel: { pageSize: 25 } }

                            }}
                            selectionModel={selectedRows}
                            onRowSelectionModelChange={handleSelectionModelChange}
                            getRowId={(row) => row.BankAccountId}
                            loading={loading}
                            autoHeight={true}
                            disableRowSelectionOnClick
                            density="comfortable"
                            apiRef={apiRef}
                            onRowClick={onRowClick}
                            disableColumnSelector={true}
                            pagination
                            {...gridWidth && { autoWidth: gridWidth }}
                            getRowHeight={() => 'auto'}
                            columns={columnList}
                            getDetailPanelContent={({ row }) =>
                                <div className={styles.tableContainerBorder}>
                                    <TableExpandableButtonRow data={{ dataContainer: ButtonRowData, IDContainer: row.BankAccountId }} />
                                    <TableExpandableDefaultContent data={getExpandableContent(row)} /></div>}
                            getDetailPanelHeight={({ row }) => 'auto'} // Height based on the content.
                            rows={accounts} />
                    </div>
                </Card.Body>
            </Card>
        </div>
    )
}

export default BankAccountList;