import { createSlice } from '@reduxjs/toolkit';

const warningMessageSlice = createSlice({
    name: 'warningMessage',
    initialState: { warningMessage: '' },
    reducers: {
        setWarning(state, action) {
            state.warningMessage = action.payload;
        },
        clearWarning(state) {
            state.warningMessage = '';
        },
    },
});

export const { setWarning, clearWarning } = warningMessageSlice.actions;
export default warningMessageSlice.reducer;