import * as React from 'react';
import { Button } from "react-bootstrap";
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import DialogContent from '@mui/material/DialogContent';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import styles from "./AuthorisationList.module.css"
import "./Overrides.css"
import DeleteIcon from '@mui/icons-material/Delete';
import { DataGridPro } from "@mui/x-data-grid-pro";
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';


const ItemRejectionProgressDialog = ({ open, onClose, onConfirm, onDeleteEntry, title, content, processing }) => {
    const [loading, setLoading] = React.useState(false);
    const [generalReason, setGeneralReason] = React.useState(0);
    const [additionalInfo, setAdditionalInfo] = React.useState("");
    const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);

    //Settings
    const showColumns = windowWidth > 900;
    const showColumnsSm = windowWidth > 768;

    function handleResize() {
        setWindowWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);

    const handleChange = (event) => {
        setGeneralReason(event.target.value);
    };

    const handleInfoChange = (event) => {
        setAdditionalInfo(event.target.value);
    };


    const handleDelete = (paymentID) => {
        // You can use onDeleteEntry function here, and pass any required parameters
        onDeleteEntry(paymentID);
    };

    const handleConfirm = () => {
        // You can use onDeleteEntry function here, and pass any required parameters
        onConfirm(generalReason, additionalInfo);
    };

    const columns = [
        { field: 'Client', headerName: 'Payer', flex: 3, minWidth: 75 },
        { field: 'PayeeName', headerName: 'Payee', flex: 3, minWidth: 75 },
        { field: 'Description', headerName: 'Description', flex: 3, minWidth: 75 },
        { field: 'PaymentAmount', headerName: 'Amount', flex: 3, minWidth: 75, valueFormatter: (params) => Number(params.value).toLocaleString(process.env.REACT_APP_CurrencyFormat) },
        { field: 'PaymentCurrencyIsoCode', headerName: 'CCY', flex: 3, minWidth: 75 },
        {
            field: 'Id',
            headerName: '',
            renderCell: (params) => {
                if (params.row.Success && params.row.Progress == 100)
                { return (<CheckCircleOutlineOutlinedIcon />); }
                else if (!params.row.Success && params.row.Progress == 0)
                { return (<CancelOutlinedIcon />); }
                else if (params.row.Progress > 10 && params.row.Progress < 100)
                { return (<CircularProgress size={20} />); }
                else
                { return (<DeleteIcon onClick={() => handleDelete(params.value)}></DeleteIcon>); }
            },
            flex: 1, minWidth: 75
        },
        // Add more columns as needed
    ];

        return (
            <Dialog
                    fullScreen
                    open={open}
                    onClose={onClose}
                sx={{
                    height: 'auto !important',
                    zIndex: "1000 !important"
                }}
            >
                <AppBar sx={{ position: 'relative' }} className={styles.processDialogAppbar}>
                    <Toolbar className={styles.processDialogToolbar}>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={onClose}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>

                        <Button disabled={processing || generalReason == 0}  className={styles.authorisationProcessButton} variant="danger" onClick={handleConfirm}>
                            Reject
                        </Button>

                        </Toolbar>
                </AppBar>
                <div className={styles.authorisationTitle}>{title}</div>

                <Box
                    component="form"
                    sx={{
                        '& > :not(style)': { m: 1, width: '25ch' },
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <div className={ styles.reasonContainer }>
                                <InputLabel htmlFor="ReasonID">
                                    Reason<span style={{ color: 'red' }}>*</span>
                                </InputLabel>
                                <Select
                                    id="ReasonID"
                                    value={generalReason}
                                    onChange={handleChange}
                                    >
                                        <MenuItem value={0} >
                                            <em>Select Reason</em>
                                        </MenuItem>
                                        <MenuItem value={'Duplicate'}>Duplicate</MenuItem>
                                        <MenuItem value={'Incorrect Info'}>Incorrect Info</MenuItem>
                                        <MenuItem value={'Not Required'}>Not Required</MenuItem>
                                        <MenuItem value={'Other'}>Other</MenuItem>
                                </Select>
                    </div>
                    <div className={styles.reasonContainer}>
                      
                        <InputLabel htmlFor="ReasonID">
                            Additional Info <span style={{ color: 'red' }}>*</span>
                        </InputLabel>
                        <TextField id="Reasoning" variant="outlined" value={additionalInfo} onChange={handleInfoChange} />
                           
                    </div>
                   
                    
                </Box>

                <div className={styles.tablePadding}>
                    <DataGridPro 
                    rows={content}
                        autoHeight 
                        columnVisibilityModel={{
                            QuestionnaireId: true,
                            Client: showColumns,
                            PaymentReasonDescription: true,
                            PayeeName: showColumns,
                            PaymentAmount: true,
                            PaymentCurrencyIsoCode: showColumnsSm,
                        }}
                    columns={columns}
                    getRowId={(row) => row.Id}
                        loading={loading}

                    />
                </div>
                </Dialog>
        );
    };

export default ItemRejectionProgressDialog;