/// <reference path="../../../elements/tables/tableexpandablebuttonrow.js" />
import { Button, Card } from "react-bootstrap";
import React, { useEffect, useState, useRef } from "react";
import '../../../Styles/Shared/content-page.css';
import TableExpandableButtonRow from '../../../Elements/Tables/TableExpandableButtonRow.js';
import TableExpandableDefaultContent from '../../../Elements/Tables/TableExpandableDefaultContent.js';
import APIService from '../../../Util/APIService.js'
import moment from "moment";
import { DataGridPro, useGridApiRef } from "@mui/x-data-grid-pro";
import { useNavigate, useLocation, Outlet, BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ItemProcessProgressDialog from './AuthorisationConfirmation.jsx';
import ItemProcessRejectDialog from './AuthorisationRejection.jsx';
import ItemAuthorisationQueueDialog from './AuthorisationQueue.jsx';
import styles from "./AuthorisationList.module.css"
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import Badge from '@mui/material/Badge';
import OTPasscode from '../../../Elements/OTPasscode/OTPasscode.js';
import "./Overrides.css"
import { setError, clearError } from '../../../Store/ErrorMessageSlice';
import { setSuccess, clearSuccess } from '../../../Store/SuccessMessageSlice';
import { setWarning, clearWarning } from '../../../Store/WarningMessageSlice';
import { useSelector, useDispatch } from "react-redux"
import { useMediaQuery } from '@mui/material';

function AuthorisationList(props) {
    //State declarations
    const [toggle, setToggle] = useState(true);;
    const [payments, setPayments] = useState([]);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [dataContainer, setDataContainer] = useState({ columns: [], rows: [] });
    const [loading, setLoading] = useState(true);
    const [gridWidth, setGridWidth] = useState(window.innerWidth);
    const [columnList, setColumnList] = useState([]);
    const [openConfirm, setOpenConfirm] = React.useState(false);
    const [openReject, setOpenReject] = React.useState(false);
    const [openQueue, setOpenQueue] = React.useState(false);
    const [rejectReason, setRejectReason] = React.useState("");
    const [additionalInfo, setAdditionalInfo] = React.useState("");
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedRowsData, setSelectedRowsData] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [countItemsInBasket, setCountItemsInBasket] = useState(0);
    const [showPins, setShowPins] = useState(false);
    const [showRejectPins, setShowRejectPins] = useState(false);
    const [isAuthItemsInBasket, setIsAuthItemsInBasket] = useState(false);
    const [authorisationTitle, setAuthorisationTitle] = useState("On 'Approve' Any queued payment/cuttoff dates needing amendment will be changed to next available day");
    const [rejectTitle, setRejectTitle] = useState("Please click 'Reject' to reject all queued payments");
    const [queueTitle, setQueueTitle] = useState("Remove items from queue you no longer wish to process");
    const dispatch = useDispatch();
    const isMobile = useMediaQuery('(max-width:900px)');

    //Settings
    const navigate = useNavigate();
    const location = useLocation();
    moment.locale('en-GB');
    const showColumns = windowWidth > 900;
    const showColumnsSm = windowWidth > 768;
    const apiRef = useGridApiRef();

    //Events
    const handleSelectionModelChange = (newSelection) => {
        setSelectedRows(newSelection);
    };

    const handleReasonChange = event => {
        setRejectReason(event.target.value);
    };

    //Effect
    useEffect(() => {       
        if (localStorage.getItem('Auth-basket')) {
            if (countItemsInBasket !== localStorage.getItem('Auth-basket').split(",").length) {
                setCountItemsInBasket(localStorage.getItem('Auth-basket').split(",").length);
            }
        }

     
            function handleResize() {
                setWindowWidth(window.innerWidth);
            }


            window.addEventListener('resize', handleResize);

            if (localStorage.getItem('Auth-basket') != null && localStorage.getItem('Auth-basket') != "") {
                setIsAuthItemsInBasket(true);
                setCountItemsInBasket(localStorage.getItem('Auth-basket').split(",").length);
            }

        
            const handleBeforeUnload = () => {
                // Cleanup logic before unloading or navigating away
                window.removeEventListener('resize', handleResize);
            };

            // Adding a custom event listener or handler
            window.addEventListener('beforeunload', handleBeforeUnload);

            // remove listners to avoid data leak
            return () => {
                window.removeEventListener('resize', handleResize);

            };
        
    }, [payments, dataContainer, countItemsInBasket, loading, toggle, location]);

    useEffect(() => {
        const timer = setInterval(() => {
            refreshIfProcessing()
            setToggle(!toggle)
            }, 3000);

        refreshIfProcessing()

        const handleBeforeUnload = () => {
            clearInterval(timer);
        };

        // Add an event listener for the beforeunload event
        window.addEventListener('beforeunload', handleBeforeUnload);
        
        // Cleanup function to clear the interval and remove the event listener
        return () => {
            clearInterval(timer);
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    async function refreshIfProcessing() {
        try {
            console.log("Payments/ProcessingBatches authlist")
            const result = await APIService.get("Payments/ProcessingBatches").then(res => {

                var questionaires = [];
                if (res != null) {
                    if (res.data != null) {
                        questionaires = res.data.PendingQuestionnaires
                        if (questionaires != null) {
                            if (questionaires.length > 0) {
                                fetchPayments(questionaires, false);
                            }
                            else {
                                fetchPayments(null, true);
                            }
                        }
                        else {
                            fetchPayments(null, true);
                        }
                    }
                }
      
            })
        } catch (error) {
            console.error('Error fetching results:', error);
        }
    }

    async function fetchPayments(excluding, interval) {
        try {
            const result = await APIService.get("payments/GetPayments").then(res => {
                var dataCon = {
                    rows: res.data
                }

                var filteredList = res.data;
                if (excluding != null) {
                    const excludeQuestionnaireIds = excluding.map(questionnaire => questionnaire.QuestionnaireId);
                    var filteredexcluding = res.data.filter((item) => !excludeQuestionnaireIds.includes(item.QuestionnaireId));
                    filteredList = filteredexcluding.filter((item) => item.CanSignOff === true);
                }
                else {
                    filteredList = res.data.filter((item) => item.CanSignOff === true);
                }

                for (var i = 0; i < filteredList.length; i++) {
                    var date1 = moment();
                    var date2 = moment(filteredList[i].CutoffTime)
                    if (date1.valueOf() > date2.valueOf()) {
                        filteredList[i].CutoffHours = "-" + date1.diff(date2, "hours").toString();
                    }
                    else {
                        filteredList[i].CutoffHours = date1.diff(date2, "hours");
                    }
                }

                if (filteredList.length !== payments.length || filteredList.length == 0) {


                    setPayments(filteredList);
                    setDataContainer(dataCon);


                    var payers = [];
                    for (var i = 0; i < res.data.length; i++) {
                        if (payers.indexOf(res.data[i].Client) == -1) {
                            payers.push(res.data[i].Client);
                        }
                    }

                    if (isMobile) {
                        const columns = [
                            { field: 'QuestionnaireId', flex: 0, minWidth: 75 },
                            { field: 'PaymentDescription', headerName: 'Description', flex: 2, minWidth: 75 },
                            { field: 'Client', headerName: 'Payer', flex: 2, minWidth: 75, type: "singleSelect", valueOptions: payers },
                            { field: 'PayerAccountName', headerName: 'Payer Account', flex: 2, minWidth: 75 },
                            { field: 'PayeeName', headerName: 'Payee', flex: 2, minWidth: 75 },
                            { field: 'PaymentReasonDescription', headerName: 'Reason', flex: 2, minWidth: 75 },
                            { field: 'PaymentTypeDescription', headerName: 'Type', flex: 2, minWidth: 75 },
                            {
                                field: 'PaymentAmount', type: 'number', headerName: 'Amount', flex: 1.5, minWidth: 90, valueFormatter: (params) => {
                                    return Number(params.value).toLocaleString(process.env.REACT_APP_CurrencyFormat, { minimumFractionDigits: params.api.getRow(params.id).PayeeCurrencyDecimalPlaces })
                                }
                            },
                            { field: 'PaymentCurrencyIsoCode', headerName: 'CCY', flex: 0.5, minWidth: 50 },
                            { field: 'PaymentMethod', headerName: 'Method', flex: 1, minWidth: 75 },
                            { field: 'Source', headerName: 'Source', flex: 1.5, minWidth: 75 },
                            { field: 'CutoffHours', type: 'number', headerName: 'Cutoff (hrs)', flex: 1, minWidth: 75 },
                            { field: 'PaymentDate', type: 'date', headerName: 'Date', flex: 1, valueFormatter: (params) => moment(params.value).format("DD/MM/YYYY"), minWidth: 75 },

                        ];
                        setColumnList(columns);
                    }
                    else {
                        const columns = [
                            { field: 'QuestionnaireId', flex: 0, minWidth: 75 },
                            { field: 'Client', headerName: 'Payer', flex: 2, minWidth: 75, type: "singleSelect", valueOptions: payers },
                            { field: 'PayerAccountName', headerName: 'Payer Account', flex: 2, minWidth: 75 },
                            { field: 'PayeeName', headerName: 'Payee', flex: 2, minWidth: 75 },
                            { field: 'PaymentReasonDescription', headerName: 'Reason', flex: 2, minWidth: 75 },
                            { field: 'PaymentTypeDescription', headerName: 'Type', flex: 2, minWidth: 75 },
                            { field: 'PaymentCurrencyIsoCode', headerName: 'CCY', flex: 0.5, minWidth: 50 },
                            {
                                field: 'PaymentAmount', type: 'number', headerName: 'Amount', flex: 1.5, minWidth: 90, valueFormatter: (params) => {
                                    return Number(params.value).toLocaleString(process.env.REACT_APP_CurrencyFormat, { minimumFractionDigits: params.api.getRow(params.id).PayeeCurrencyDecimalPlaces })
                                }
                            },
                            { field: 'PaymentMethod', headerName: 'Method', flex: 1, minWidth: 75 },
                            { field: 'Source', headerName: 'Source', flex: 1.5, minWidth: 75 },
                            { field: 'CutoffHours', type: 'number', headerName: 'Cutoff (hrs)', flex: 1, minWidth: 75 },
                            { field: 'PaymentDate', type: 'date', headerName: 'Date', flex: 1.5, valueFormatter: (params) => moment(params.value).format("DD/MM/YYYY"), minWidth: 75 },

                        ];
                        setColumnList(columns);
                    }
                }


                setLoading(false);
            })
                .catch(error => {
                    navigate('/ErrorPage', { state: error.message });
                });
        }
        catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    //Data
    const ButtonRowData = [{ route: "/PaymentView", description: "View" }];
    function getExpandableContent(row) {
        var retVar = [{ label: "Payee", value: row.PayeeName },
        { label: "Payer", value: row.Client },
        { label: "Payer Account", value: row.PayerAccountName },
        { label: "Payment date", value: moment(row.PaymentDate).format("DD/MM/YYYY") },
        { label: "Reason", value: row.PaymentReasonDescription },
        { label: "CCY", value: row.PaymentCurrencyIsoCode },
        { label: "Amount", value: Number(row.PaymentAmount).toLocaleString(process.env.REACT_APP_CurrencyFormat, { minimumFractionDigits: row.PayeeCurrencyDecimalPlaces }) },
        { label: "Method", value: row.PaymentMethod },
        { label: "Source", value: row.Source },
        ]
        return retVar;
    }

    const deleteAuthorisationEntry = (paymentID) => {
        // need remove from Auth-basket too!!
        var authbasket = localStorage.getItem('Auth-basket').split(',');
        var index = authbasket.indexOf(paymentID.toString());
        if (index !== -1) {
            authbasket.splice(index, 1);
            localStorage.setItem('Auth-basket', authbasket)
        }


        const newArr = selectedRowsData.filter(object => {
            return object.QuestionnaireId !== paymentID;
        });

        var newIDArray = [];
        for (var i = 0; i < newArr.length; i++) {
            newIDArray.push(newArr[i].Id);
        }
        apiRef.current.selectRows(newIDArray, true, true);


        setCountItemsInBasket(newArr.length);


        setSelectedRowsData(newArr)
        if (newArr.length == 0) {
            setIsAuthItemsInBasket(false);
            setOpenDialog(false);
            setCountItemsInBasket(0);
        }
    }

    const deleteAuthorisationQueueEntry = (paymentID) => {
        // need remove from Auth-basket too!!
        var authbasket = localStorage.getItem('Auth-basket').split(',');
        var index = authbasket.indexOf(paymentID.toString());
        if (index !== -1) {
            authbasket.splice(index, 1);
            localStorage.setItem('Auth-basket', authbasket)
        }


        const newArr = selectedRowsData.filter(object => {
            return object.QuestionnaireId !== paymentID;
        });

        setCountItemsInBasket(newArr.length);

        var newIDArray = [];
        for (var i = 0; i < newArr.length; i++) {
            newIDArray.push(newArr[i].Id);
        }
        apiRef.current.selectRows(newIDArray, true, true);

        setSelectedRows(newIDArray);
        setSelectedRowsData(newArr)


        if (newArr.length == 0) {
            setIsAuthItemsInBasket(false);
            setOpenQueue(false);
            setCountItemsInBasket(0);
        }
    }

    const deleteAuthorisationEntryReject = (paymentID) => {
        // need remove from Auth-basket too!!
        var authbasket = localStorage.getItem('Auth-basket').split(',');
        var index = authbasket.indexOf(paymentID.toString());
        if (index !== -1) {
            authbasket.splice(index, 1);
            localStorage.setItem('Auth-basket', authbasket)
        }


        const newArr = selectedRowsData.filter(object => {
            return object.QuestionnaireId !== paymentID;
        });

        setCountItemsInBasket(newArr.length);

        var newIDArray = [];
        for (var i = 0; i < newArr.length; i++) {
            newIDArray.push(newArr[i].Id);
        }
        apiRef.current.selectRows(newIDArray, true, true);


        setSelectedRowsData(newArr)
        if (newArr.length == 0) {
            setIsAuthItemsInBasket(false);
            setOpenReject(false);
            setCountItemsInBasket(0);
        }
    }


    // button clicks
    const handleClickOpenConfirm = () => {
        if (localStorage.getItem('Auth-basket') == "") {
            localStorage.removeItem('Auth-basket');
        }

        for (var i = 0; i < selectedRows.length; i++) {
            var selectedID = selectedRows[i];

            if (localStorage.getItem('Auth-basket') != null && checkIfBasketContains(localStorage.getItem('Auth-basket'), selectedID.toString())) {
                //console.log("already contains")
            }
            else {
                if (localStorage.getItem('Auth-basket') != null) {
                    var authbasket = localStorage.getItem('Auth-basket').split(',');
                    authbasket.push(selectedID.toString());
                    localStorage.setItem('Auth-basket', authbasket)
                }
                else {
                    localStorage.setItem('Auth-basket', selectedID.toString())
                }
            }
        }

        var authbasket = localStorage.getItem('Auth-basket').split(',');
        if (authbasket.length > 0) {
            setCountItemsInBasket(authbasket.length);
            setIsAuthItemsInBasket(true);
        }
        const selectedRowsData = authbasket.map((id) => payments.find((row) => row.QuestionnaireId.toString() === id));
        for (var i = 0; i < selectedRowsData.length; i++) {
            selectedRowsData[i].Progress = 10;
            selectedRowsData[i].Id = selectedRowsData[i].QuestionnaireId;
            selectedRowsData[i].Description = selectedRowsData[i].PaymentReasonDescription;
            selectedRowsData[i].Success = true;
            selectedRowsData[i].Colour = "info"
        }

        setSelectedRowsData(selectedRowsData)
        setOpenDialog(true);
    };

    const handleClickOpenReject = () => {
        if (localStorage.getItem('Auth-basket') == "") {
            localStorage.removeItem('Auth-basket');
        }

        for (var i = 0; i < selectedRows.length; i++) {
            var selectedID = selectedRows[i];

            if (localStorage.getItem('Auth-basket') != null && checkIfBasketContains(localStorage.getItem('Auth-basket'), selectedID.toString())) {
                //console.log("already contains")
            }
            else {
                if (localStorage.getItem('Auth-basket') != null) {
                    var authbasket = localStorage.getItem('Auth-basket').split(',');
                    authbasket.push(selectedID.toString());
                    localStorage.setItem('Auth-basket', authbasket)
                }
                else {
                    localStorage.setItem('Auth-basket', selectedID.toString())
                }
            }
        }

        var authbasket = localStorage.getItem('Auth-basket').split(',');
        if (authbasket.length > 0) {
            setCountItemsInBasket(authbasket.length);
            setIsAuthItemsInBasket(true);
        }
        const selectedRowsData = authbasket.map((id) => payments.find((row) => row.QuestionnaireId.toString() === id));
        for (var i = 0; i < selectedRowsData.length; i++) {
            selectedRowsData[i].Progress = 10;
            selectedRowsData[i].Id = selectedRowsData[i].QuestionnaireId;
            selectedRowsData[i].Description = selectedRowsData[i].PaymentReasonDescription;
            selectedRowsData[i].Success = true;
            selectedRowsData[i].Colour = "info"
        }

        setSelectedRowsData(selectedRowsData)
        setOpenReject(true);
    };

    const handleClickOpenQueue = () => {
        if (localStorage.getItem('Auth-basket') == "") {
            localStorage.removeItem('Auth-basket');
        }

        var authbasket = localStorage.getItem('Auth-basket').split(',');

        const selectedRowsData = authbasket.map((id) => payments.find((row) => row.QuestionnaireId.toString() === id));
        for (var i = 0; i < selectedRowsData.length; i++) {
            selectedRowsData[i].Progress = 10;
            selectedRowsData[i].Id = selectedRowsData[i].QuestionnaireId;
            selectedRowsData[i].Description = selectedRowsData[i].PaymentReasonDescription;
            selectedRowsData[i].Success = true;
            selectedRowsData[i].Colour = "info"
        }

        setSelectedRowsData(selectedRowsData)
        setOpenQueue(true);
    };

    const handleCloseConfirm = () => {
        if (isProcessing == false) {
            setIsProcessing(false);
            setOpenDialog(false);
            setShowPins(false);
            setAuthorisationTitle("please click 'process' to authorise all payments");
        }
    };

    const handleCloseReject = () => {
        setIsProcessing(false);
        setOpenReject(false);
        setShowRejectPins(false);
        setRejectTitle("please click 'Reject' to Reject all payments");
    };

    const handleCloseQueue = () => {
        setIsProcessing(false);
        setOpenQueue(false);
    };

    const handleAddToBasket = () => {
        if (localStorage.getItem('Auth-basket') == "") {
            localStorage.removeItem('Auth-basket');
        }
        for (var i = 0; i < selectedRows.length; i++) {
            var selectedID = selectedRows[i];

            if (localStorage.getItem('Auth-basket') != null && checkIfBasketContains(localStorage.getItem('Auth-basket'), selectedID.toString())) {
                //console.log("already contains")
            }
            else {
                if (localStorage.getItem('Auth-basket') != null) {
                    var authbasket = localStorage.getItem('Auth-basket').split(',');
                    authbasket.push(selectedID.toString());
                    localStorage.setItem('Auth-basket', authbasket)
                    setIsAuthItemsInBasket(true);
                }
                else {
                    localStorage.setItem('Auth-basket', selectedID.toString())
                    setIsAuthItemsInBasket(true);
                }
                setCountItemsInBasket(localStorage.getItem('Auth-basket').split(",").length);
            }
        }
    };

    function checkIfBasketContains(basket, item) {
        if (localStorage.getItem('Auth-basket') != null && localStorage.getItem('Auth-basket').split(',').includes(item)) {
            return true;
        }
        else {
            return false;
        }
    }

    const handleCloseConfirmComplete = () => {
        PreApproveConfirmPayment()
        setCountItemsInBasket(0);
        localStorage.setItem('Auth-basket', "")
        setOpenConfirm(false);
    };

    const handleCloseRejectionComplete = (rejectReasoning, additionalInformation) => {
        setAdditionalInfo(additionalInformation);
        setRejectReason(rejectReasoning)
        localStorage.setItem('Auth-basket', "")
        setCountItemsInBasket(0);
        PreApproveRejectPayment(rejectReasoning, additionalInformation)
    };

    const handleQueueComplete = () => {
        localStorage.removeItem('Auth-basket');
        setCountItemsInBasket(0);
        setIsAuthItemsInBasket(false);
        setOpenQueue(false);
    };

    const onRowClick = (params) => {
        apiRef.current.toggleDetailPanel(params.id);
    }

    const handleBulkPaymentConfirmationAudit = async (paymentCount) => {
        // Perform your export logic here
        var parameterData = paymentCount + ' Payments were sent for bulk approve'
        const result = await APIService.post("User/Audit", parameterData).then(res => { });
    };

    const handleBulkPaymentRejectAudit = async (paymentCount) => {
        // Perform your export logic here
        var parameterData = paymentCount + ' Payments were sent for bulk reject'
        const result = await APIService.post("User/Audit", parameterData).then(res => { });
    };

    //Api calls
    async function PreApproveRejectPayment(rejectReason, additionalInfo) {
        var ids = [];
        for (var i = 0; i < selectedRowsData.length; i++) {
            ids.push(selectedRowsData[i].Id)
        }
        var reasonCode = rejectReason;
        var info = rejectReason + ": " + additionalInfo;
        setLoading(true);
        var dataObject = { questionnaireIds: ids, reason: info, code: "" }
        handleBulkPaymentRejectAudit(ids.length);
        // under views authorisation > authorisation confirmation (not this folder)
        navigate('/AuthorisationConfirmation', { state: { previous: "/", routing: "Payments/RejectPayments", routeObject: dataObject } });
    }


    async function PreApproveConfirmPayment() {
        var ids = [];
        for (var i = 0; i < selectedRowsData.length; i++) {
            ids.push(selectedRowsData[i].Id)
        }

        setLoading(true);
        var dataObject = { questionnaireIds: ids, reason: "", code: "" }
        handleBulkPaymentConfirmationAudit(ids.length);
        // under views authorisation > authorisation confirmation (not this folder)
        navigate('/AuthorisationConfirmation', { state: { previous: "/", routing: "Payments/ConfirmPayments", routeObject: dataObject } });

    }




    const handleOpenFilterPanel = () => {
        apiRef.current.showFilterPanel("Client");
    };

    const sortModel = [
        {
            field: 'CutoffHours', // Replace with the actual field name you want to sort by
            sort: 'asc', // 'desc' for descending order
        },
    ];


    // View
    return (
        <div>
            <ItemProcessRejectDialog
                open={openReject}
                onClose={handleCloseReject}
                onConfirm={handleCloseRejectionComplete}
                onDeleteEntry={deleteAuthorisationEntryReject}
                title={rejectTitle}
                content={selectedRowsData}
                processing={isProcessing}
            />
            <ItemAuthorisationQueueDialog
                open={openQueue}
                onClose={handleCloseQueue}
                onConfirm={handleQueueComplete}
                onDeleteEntry={deleteAuthorisationQueueEntry}
                title={queueTitle}
                content={selectedRowsData}
                processing={isProcessing}
            />
            <ItemProcessProgressDialog
                open={openDialog}
                onClose={handleCloseConfirm}
                onConfirm={handleCloseConfirmComplete}
                onDeleteEntry={deleteAuthorisationEntry}
                title={authorisationTitle}
                content={selectedRowsData}
                processing={isProcessing}
            />
            <div className="nav-action-bar hide-on-portrait-phone">
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="/">
                        Home
                    </Link>
                    <Link
                        underline="none"
                        color="inherit"
                    >
                        Authorisation List
                    </Link>
                </Breadcrumbs>
                <div className="action-button-position">
                    <Button className={styles.authorisationButton} variant="primary" onClick={handleClickOpenConfirm} disabled={selectedRows < 1 && isAuthItemsInBasket == false} type="success" loading={loading.toString()}>
                        Approve
                    </Button>
                    <Button className={styles.authorisationButton} variant="primary" onClick={handleClickOpenQueue} disabled={isAuthItemsInBasket == false} type="success" loading={loading.toString()}>
                        <EditNoteOutlinedIcon></EditNoteOutlinedIcon>
                    </Button>
                    <Button className={styles.authorisationButton} variant="primary" onClick={handleAddToBasket} disabled={selectedRows < 1 && isAuthItemsInBasket == false} type="success" loading={loading.toString()}>
                        <Badge badgeContent={countItemsInBasket} color="primary"><PlaylistAddIcon></PlaylistAddIcon></Badge>
                    </Button>
                    <Button className={styles.authorisationButton} variant="primary" onClick={handleClickOpenReject} disabled={selectedRows < 1 && isAuthItemsInBasket == false} type="danger" loading={loading.toString()}>
                        Reject
                    </Button>
                </div>
            </div>
            <div className="nav-action-bar show-on-portrait-phone">
                <div className="">
                    <div className="action-button-position">
                        <Button className={styles.authorisationButton} variant="primary" onClick={handleClickOpenConfirm} disabled={selectedRows < 1 && isAuthItemsInBasket == false} type="success" loading={loading.toString()}>
                            Approve
                        </Button>
                        <Button className={styles.authorisationButton} variant="primary" onClick={handleClickOpenQueue} disabled={isAuthItemsInBasket == false} type="success" loading={loading.toString()}>
                            <EditNoteOutlinedIcon></EditNoteOutlinedIcon>
                        </Button>
                        <Button className={styles.authorisationButton} variant="primary" onClick={handleAddToBasket} disabled={selectedRows < 1 && isAuthItemsInBasket == false} type="success" loading={loading.toString()}>
                            <Badge badgeContent={countItemsInBasket} color="primary"><PlaylistAddIcon></PlaylistAddIcon></Badge>
                        </Button>
                        <Button className={styles.authorisationButton} variant="primary" onClick={handleClickOpenReject} disabled={selectedRows < 1 && isAuthItemsInBasket == false} type="danger" loading={loading.toString()}>
                            Reject
                        </Button>
                    </div></div>
            </div>

            <Card className="list-page-padding">
                <TuneOutlinedIcon className={styles.filterIcon} onClick={handleOpenFilterPanel}></TuneOutlinedIcon>
                <Card.Body>
                    <div style={{ width: "100%" }}>
                        <DataGridPro
                            width="100%"
                            pageSizeOptions={[15, 25, 50, 100, 250]}
                            columnVisibilityModel={{
                                QuestionnaireId: false,
                                PayeeName: showColumns,
                                Client: showColumns,
                                PaymentReasonDescription: true,
                                PaymentTypeDescription: showColumns,
                                Source: showColumns,
                                CutoffHours: showColumns,
                                PaymentDate: showColumns,
                                PayerAccountName: showColumns,
                                PaymentAmount: showColumns,
                                PaymentCurrencyIsoCode: showColumns,
                                PaymentMethod: false,
                                Inbox: showColumns,
                                __detail_panel_toggle__: showColumns
                            }}
                            initialState={{
                                pagination: { ...dataContainer.initialState?.pagination, paginationModel: { pageSize: 25 } }
                            }}
                            sortModel={sortModel}
                            checkboxSelection
                            selectionModel={selectedRows}
                            onRowSelectionModelChange={handleSelectionModelChange}
                            getRowId={(row) => row.QuestionnaireId}
                            autoHeight={true}
                            loading={loading}
                            disableRowSelectionOnClick
                            apiRef={apiRef}
                            onRowClick={onRowClick}
                            density="comfortable"
                            disableColumnSelector={true}
                            pagination
                            {...gridWidth && { autoWidth: gridWidth }}
                            getRowHeight={() => 'auto'}
                            columns={columnList}
                            getDetailPanelContent={({ row }) =>
                                <div className={styles.tableContainerBorder}>
                                    <TableExpandableButtonRow data={{ dataContainer: ButtonRowData, IDContainer: row.QuestionnaireId }} />
                                    <TableExpandableDefaultContent data={getExpandableContent(row)} /></div>}
                            getDetailPanelHeight={({ row }) => 'auto'} // Height based on the content.
                            rows={payments} />
                    </div>
                </Card.Body>
            </Card>
        </div>
    )
}

export default AuthorisationList;