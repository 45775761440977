
import { Card } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Link } from 'react-router-dom';
import { useNavigate, Outlet, BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Button } from "react-bootstrap";


function ErrorPage(props) {
    const location = useLocation();
    const [error, setError] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        setError(location.state.Message);
        if (location.state.Message) {
            if (location.state.Message.includes("Token has timed out") || location.state.Message.includes("Token not found")) {
                localStorage.setItem('token-error', "Login has expired. Please sign in again.");
                localStorage.removeItem("user-token");
                navigate('/');
                //should somehow pass a message to login screen to say timed out.
                window.location.reload(false);
            }
        }
        else {
            localStorage.setItem('token-error', "Error Has Occurred. Please try again.");
            /*localStorage.removeItem("user-token");*/
           /* navigate('/');*/
            //should somehow pass a message to login screen to say timed out.
         /*   window.location.reload(false);*/
        }
    }, []);

    function logout() {
        localStorage.clear();

        navigate('/');
        //should somehow pass a message to login screen to say timed out.
        window.location.reload(false);
    }

    function returnToDashboard() {
        navigate('/');
    }

    return (
    <div maxWidth="sm" style={{ textAlign: 'center', marginTop: '40px' }}>
        <Typography variant="h1" component="h2" gutterBottom>
            Oops!
        </Typography>
        <Typography variant="h5" component="h3" gutterBottom>
            Something went wrong.
        </Typography>
        <Typography variant="body1">
            We're sorry, but an error occurred while processing your request.
          
            </Typography>
            <Typography style={{ margin: '20px', color: 'palevioletred', fontWeight: 'bold' }} variant="body2">
                {error }
            </Typography>
            <Button style={{ margin: '30px' }} type="primary" onClick={() => returnToDashboard()}>
                Return to Home Page
            </Button>
        </div>
       )
}


    //const location = useLocation();
    //const [error, setError] = useState([]);
    //const navigate = useNavigate();
    ///*    const { confirm } = Modal;*/

    //useEffect(() => {
    //    setError(location.state);
    //}, []);

    //function returnToDashboard() {
    //    navigate('/');
    //}

    //return (
    //    <div>
    //        <Result
    //            status="500"
    //            title={error}
    //            subTitle="Oops! Something went wrong."
    //            extra={
    //                <Button type="primary" onClick={() => returnToDashboard()}>
    //                    Return to dashboard
    //                </Button>
    //            }
    //        />
    //    </div>

    //)

export default ErrorPage;