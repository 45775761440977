import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { Button, Col, Container, Form, FormGroup, FormLabel, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
/*import './Login.css';*/
import BCBigImg from "../../../Assets/Images/bcbigimg.png"
import BankClarityLogo from "../../../Assets/Images/VistraTheme/BankClarityBrand.png"
import PoweredByLogo from "../../../Assets/Images/Powered by BC white.png"
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import styles from "./Login.module.css"
import userManager from '../../../Util/AuthService';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import MenuItem from '@mui/material/MenuItem';
import DialogActions from '@mui/material/DialogActions';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';

const Login = () => {

    //TODO
    const loginAPI = process.env.REACT_APP_APIBaseURL + "/" + process.env.REACT_APP_APIName + 'Authentication/Authenticate';
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(true);
    const [showWarning, setShowWarning] = React.useState(false);
    const [showSuccess, setShowSuccess] = React.useState(false);
    const [showSecondStage, setShowSecondStage] = React.useState(false);
    const [formJSON, setFormJSON] = React.useState(null);
    const [tenantDialogOpen, setTenantDialogOpen] = useState(false);
    const [tenantSelectedOption, setTenantSelectedOption] = useState('');
    const [tenantOptions, setTenantOptions] = useState([]);

    useEffect(() => {
        if (localStorage.getItem('token-error') == null || localStorage.getItem('token-error') == "") {
            setShowWarning(false);
        }
        else {
            setShowWarning(true);
        }

        if (localStorage.getItem('token-success') == null || localStorage.getItem('token-success') == "") {
            setShowSuccess(false);
        }
        else {
            setShowSuccess(true);
        }

        const response = axios.get(process.env.REACT_APP_APIBaseURL + "/" + process.env.REACT_APP_APIName + 'Authentication/AvailableTenants').then(res => {
            var tenantOptionsList = [];
            for (var i = 0; i < res.data.length; i++) {
                tenantOptionsList.push({ label: res.data[i].Description, value: res.data[i].Name })
            }

            tenantOptionsList.sort(function (a, b) {
                var labelA = a.label.toUpperCase(); // ignore case
                var labelB = b.label.toUpperCase(); // ignore case
                if (labelA < labelB) {
                    return -1;
                }
                if (labelA > labelB) {
                    return 1;
                }
                return 0; // labels are equal
            });

            setTenantOptions(tenantOptionsList)
            if (tenantOptionsList.length < 2 && tenantOptionsList.length > 0) {
                setTenantSelectedOption(tenantOptionsList[0].value);
            }
        })
            .catch(error => {
         

            });;
       

    }, []);

    function OpenIDSignIn() {
        localStorage.setItem('passedSSO', "undefined");
        userManager.signinRedirect();
    }


    const submitLoginForm = async (event) => {
        event.preventDefault();
        const formElement = document.querySelector('#loginForm');
        const formData = new FormData(formElement);
        const btnPointer = document.querySelector('#login-btn');
        setFormJSON(formData);
        btnPointer.innerHTML = 'Please wait..';
        btnPointer.setAttribute('disabled', true);
        // send off to check if valid password (precheck)
        try {
            const loginReadyForMFA = await performLogin(Object.fromEntries(formData), "");
            if (loginReadyForMFA === "MFA") {
                setShowSecondStage(true);
            } else if (loginReadyForMFA === "Tenant") {
                setTenantDialogOpen(true);
                // do tenant stuff
            } else {
                // Just login and carry on
            }

            btnPointer.innerHTML = 'Login';
            btnPointer.removeAttribute('disabled');
        } catch (error) {
            // Handle error here
        }

      
    }

    const submitLoginSecondAuthForm = (event) => {
        const formDataJSON = Object.fromEntries(formJSON);
        const formElement2 = document.querySelector('#loginForm2');
        const formData2 = new FormData(formElement2);
        const btnPointer = document.querySelector('#second-login-btn');
        const passwordField = document.querySelector('#login-password');
        btnPointer.innerHTML = 'Please wait..';
        btnPointer.setAttribute('disabled', true);
        var pins = "";
        for (const value of formData2.values()) {
            pins = pins + value;
        }
        performLogin(formDataJSON, pins);
        btnPointer.innerHTML = 'Login';
        btnPointer.removeAttribute('disabled');
        
    }

    async function performLogin(formDataJSON, pins) {
        try {
            if (pins != null && pins != "") {
                formDataJSON.Code = pins;
            }
            else {
                formDataJSON.Code = "";
            }
            formDataJSON.Tenant = "";
            if (tenantSelectedOption != "" && tenantSelectedOption != null) {
                formDataJSON.Tenant = tenantSelectedOption;
            }
            const response = await axios.post(loginAPI, formDataJSON);
            const token = response.data;
            if (token == null || token === "") {
                return;
            }
            localStorage.clear();
            localStorage.setItem('user-token', token);
            setTimeout(() => {
                window.location.reload(false);
                navigate('/');
            }, 500);

        } catch (error) {
            if (error.response.data.Message == "Code required") {
                return "MFA";
            }
            else if (error.response.data.Message == "Code required") {
                return "MFA";
            }
            else if ((error.response.data.Message != null && error.response.data.Message.includes("Could not determine tenant for user"))
                || (error.response.data != null && typeof error.response.data === 'string' && error.response.data.includes("Could not determine tenant for user"))) {
                return "Tenant";
            }
            else {
                    localStorage.setItem('token-error', "Login failed, Please check details and try again")
                    window.location.reload(false);
                    return;
            }
           
        }
       
    }

    function hideSecondStage(){
        setShowSecondStage(false);
    }

    const handleTenantClose = () => {
        setTenantSelectedOption('');
        setTenantDialogOpen(false);
    };

    const handleTenantConfirm = () => {
        const formDataJSON = Object.fromEntries(formJSON);
        setTenantDialogOpen(false);
        performLogin(formDataJSON, null).then(res => {
            if (res === "MFA") {
                setShowSecondStage(true);
            } else if (res === "Tenant") {
                setTenantDialogOpen(true);
                // do tenant stuff
            } else {
                // Just login and carry on
            }
        });

    };

    const handleTenantOptionChange = (event) => {
        setTenantSelectedOption(event.target.value);
    };

    function forgotPassword() {
        navigate('/sendresetrequest');
    }



    return (
        <React.Fragment>
            <div className={styles.loginContainer}>
                <Dialog fullWidth={true} maxWidth={"lg"} open={tenantDialogOpen} onClose={handleTenantClose}>
                    <DialogTitle>Select an Tenant</DialogTitle>
                    <DialogContent>
                        <Select
                            displayEmpty
                            value={tenantSelectedOption}
                            onChange={handleTenantOptionChange}
                        >
                            <MenuItem disabled value="">
                                <em> Please select </em>
                            </MenuItem>
                            {tenantOptions.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleTenantClose}>Cancel</Button>
                        <Button onClick={handleTenantConfirm}>Confirm</Button>
                    </DialogActions>
                </Dialog>

                {showWarning && <div className={styles.loginAlertContainer}>
                    <Collapse in={open}>
                        <Alert severity="warning" className={styles.loginAlert}
                            action={
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                        setOpen(false);
                                    }}
                                >
                                    <CloseIcon fontSize="inherit" />
                                </IconButton>
                            }
                            sx={{ mb: 2 }}
                        >
                            { localStorage.getItem('token-error') }
                        </Alert>
                    </Collapse>
                </div>
                }
                {showSuccess && <div className={styles.loginAlertContainer}>
                    <Collapse in={open}>
                        <Alert severity="success" className={styles.loginAlert}
                            action={
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                        setOpen(false);
                                    }}
                                >
                                    <CloseIcon fontSize="inherit" />
                                </IconButton>
                            }
                            sx={{ mb: 2 }}
                        >
                            {localStorage.getItem('token-success')}
                        </Alert>
                    </Collapse>
                </div>
                }
                <div className={styles.AuthFormContainer}>
                    {!showSecondStage && <Container className={styles.AuthForm}>
   
                        <Row>
                           
                            <Col md={{ span: 6 }} className={styles.removePadding}>
                                <div className="BankClarityLoginSideImage"></div>
                         
                            </Col>
                            <Col md={{ span: 6 }} >
                                <div className={styles.AuthFormContent}>
                                    <div className="form-group mt-3">
                                        <div className={styles.loginLogo}>
                                            <div className="BankClarityLogo"></div> 
                                        
                                        </div>
                                        <Form id="loginForm" onSubmit={submitLoginForm} className={styles.loginFormContainer + " " + styles.loginWrapCard}>

                                            <FormGroup className="mb-3">
                                                <FormLabel htmlFor={'login-username'}>Username</FormLabel>
                                                <input type={'text'} className="form-control" id={'login-username'} name="UserName" required />
                                            </FormGroup>
                                            <FormGroup className="mb-3">
                                                <FormLabel htmlFor={'login-password'}>Password</FormLabel>
                                                <input type={'password'} className="form-control" id={'login-password'} name="Password" required />
                                            </FormGroup>
                                            <div className={styles.loginButtonHolder}>
                                                <a onClick={forgotPassword}>Forgot password?</a>

                                                <Button type="submit" className={styles.loginButton + ' btn-success mt-2'} id="login-btn">Login</Button>

                                            </div>

                                           

                                        </Form>
                                        {process.env.REACT_APP_AllowSSO == "true" &&  <div><div className={styles.orHolder}>
                                                OR
                                            </div>
                                            <Button onClick={OpenIDSignIn} className={'btn-success mt-2 ' + styles.loginButton} id="login-btn-ss">Single Sign On</Button></div>}
                                    </div>
                                </div>
                            </Col>

                        </Row>

                    </Container>
                    }
                    {showSecondStage &&
                        <Container className={styles.AuthForm}>
                            <Row>
                                <Col md={{ span: 12 }} >
                                    <div onClick={hideSecondStage}><ArrowBackIcon className={styles.secondBackButton}></ArrowBackIcon> Return </div>
                                    <div className={styles.AuthFormContent}>

                                        <div className="form-group mt-3">
                                            <div className={styles.loginLogo}>
                                                    <div className="BankClarityLoginLogo"></div>
                                            </div>
                                            <Form id="loginForm2" method="POST" className={styles.loginFormContainer + " " + styles.loginWrapCard}>
                                                <div className={styles.pinCodeInstructions}> Please enter your one time passcode </div>
                                                <FormGroup className="mb-3">
                                                    <Box sx={{ width: '100%' }}>
                                                        <div className={styles.pinInput}>
                                                            <input name="pincode-1" type="text"></input>
                                                        </div>

                                                        </Box>
                                                </FormGroup>
                                                <div className={styles.pinButtonContainer}>
                                                    <Button onClick={submitLoginSecondAuthForm} type="Button" className={"btn-success mt-2 " + styles.loginButton + " " + styles.pinButton} id="second-login-btn">Login</Button>
                                                </div>
                                               
                                            </Form> </div>
                                    </div>
                                </Col>

                            </Row>
                
                        </Container>
                    }
                    <img src={PoweredByLogo} className={styles.poweredBy} width="auto"></img>
                    <div className={styles.loginSupport}> 
                        <span className="preserveFormat"><SupportAgentIcon /><a className={styles.spaceLeft + " " + styles.loginSupportText} href={process.env.REACT_APP_SupportURL}>{process.env.REACT_APP_SupportName}</a></span>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Login;