
import React from 'react';
import '../Home.css';
import '../../../Styles/Shared/content-page.css';
import BarChart from '../../../Elements/Charting/BarChart.js';
import AreaChart from '../../../Elements/Charting/AreaChart.js';
import LineChart from '../../../Elements/Charting/LineChart.js';
import PieChart from '../../../Elements/Charting/PieChart.js';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import TransactionsByDatePlugin from '../../../Elements/Plugins/TransactionsByDatePlugin';


const chartData = {
    categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug"], title: "Approved Transactions", series: [{ seriesName: "Transactions", seriesData: [10, 2, 5, 8, 20, 18, 18, 2] }]};

const chartData2 = {
    categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug"], title: "Rejected Transactions", series: [{ seriesName: "Transactions", seriesData: [1, 2, 5, 1, 1, 1, 1, 2] }]
};

const chartData3 = {
    categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug"], title: "Account Balance", series: [{ seriesName: "Balance", seriesData: [100000, 200000, 150000, 100000, 100000, 100000, 100000, 20000] }]
};

const chartData4 = {
    categories: ["Q1", "Q2", "Q3", "Q4"], title: "Total Transactions", series: [{ seriesName: "Transactions", seriesData: [80, 20, 50, 80] }]
};


const DashboardContainer = () => {


    return (
        <div>
            <Container className="dashboard-container">
                <Row>
                    <Col sm={12} md={6} lg={4}>
                        <TransactionsByDatePlugin data={chartData} />
                    </Col>
                    <Col sm={12} md={6} lg={4}>                      
                        <TransactionsByDatePlugin data={chartData2} />
                    </Col>
                    <Col sm={12} md={6} lg={4}>
                        <TransactionsByDatePlugin data={chartData4} />
                    </Col>
                    <Col sm={12} md={6} lg={6}>
                        <TransactionsByDatePlugin data={chartData3} />
                    </Col>
                </Row>
            </Container>
        </div>

    );
}
export default DashboardContainer;
