import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import { Button, Card } from "react-bootstrap";

const BankStatementChart = ({ data }) => {
    const [options, setOptions] = useState({
        chart: {
            id: "area-chart",
            animations: {
                speed: 250, //make the transition faster
            },
        },
        dataLabels: {
            enabled: false,
        },
        colors: ["var(--chart-primary)"],
        fill: {
            type: 'gradient',
            gradient: {
                shadeIntensity: 0.1,
                opacityFrom: 0.5,
                opacityTo: 0,
                stops: [0, 90, 100]
            }
        },
        xaxis: {
            type: 'datetime',
            categories: data.categories
        },
    });

    const [series, setSeries] = useState([
        {
            name: data.series[0].seriesName,
            data: data.series[0].seriesData
        }
    ]);

    const [title, setTitle] = useState(data.title);

    useEffect(() => {
        setOptions({
            ...options,
            xaxis: {
                ...options.xaxis,
                categories: data.categories
            }
        });
        setSeries([
            {
                name: data.series[0].seriesName,
                data: data.series[0].seriesData
            }
        ]);
        setTitle(data.title);
    }, [data]);

    return (
        <Card className="" style={{ height: "305px" }}>
            <Card.Header>Account Balance</Card.Header>
            <div className="plugin-content">
                <div className="row">
                    <div className="mixed-chart">
                        <Chart
                            options={options}
                            series={series}
                            type="area"
                            height="230px"
                        />
                    </div>
                </div>
            </div>
        </Card>
    );
};

export default BankStatementChart;