import userManager from '../../Util/AuthService';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { Button, Col, Container, Form, FormGroup, FormLabel, Row } from "react-bootstrap";
import DialogContent from '@mui/material/DialogContent';
import MenuItem from '@mui/material/MenuItem';
import DialogActions from '@mui/material/DialogActions';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import OTPasscode from '../../Elements/OTPasscode/OTPasscode.js';
import APIService from '../../Util/APIService.js'
import { useSelector, useDispatch } from "react-redux"
import { setError, clearError } from '../../Store/ErrorMessageSlice';
import { setWarning, clearWarning } from '../../Store/WarningMessageSlice';
import { setSuccess, clearSuccess } from '../../Store/SuccessMessageSlice';
import { useLocation } from "react-router-dom";

function AuthorisationConfirmation() {
    const location = useLocation();
    const navigate = useNavigate();
    const [dialogOpen, setDialogOpen] = useState(false);
    const dispatch = useDispatch();
    const previousRoute = useSelector((state) => state.redirectPreviousRoute.redirectPreviousRoute);

    const handleCloseConfirm = () => {
        dispatch(setWarning("operation cancelled"))
        navigate(location.state.previous);

        // go back to prev
    };

    async function AttemptApproveConfirmation(pincode) {
        setDialogOpen(false);
        location.state.routeObject.code = pincode;
        const processResult = await APIService.post(location.state.routing, location.state.routeObject).then(res => {
            dispatch(setSuccess("Items Authorised for processing"))
            navigate(location.state.previous);
            // navigate back but show confirmation of processing
        }).catch(error => {
            if (error.response.data == "Code required") {
                setDialogOpen(true);
            }
            else if (error.response.data == "SSO Required") {
                // doo something for sso
            }
            else if (error.response.data == "Invalid code") {
                dispatch(setError(error.message))
                setDialogOpen(true);
            }
            else {
                // navigate back but show error
                navigate(location.state.previous);
                dispatch(setError(error.message))
            }
        });
    }

    useEffect(() => {
        const handleCall = async () => {
            const processResult = await APIService.post(location.state.routing, location.state.routeObject).then(res => {
                // navigate back but show confirmation of processing
                dispatch(setSuccess("Items Authorised for processing"))
                navigate(location.state.previous);
            }).catch(error => {
                if (error.response.data == "Code required") {
                    setDialogOpen(true);
                }
                else if (error.response.data == "SSO Required") {
                    // doo something for sso
                }
                else {
                    // navigate back but show error
                    navigate(location.state.previous);
                    dispatch(setError(error.message))
                }
            });
        };

        handleCall();
    }, []);
    return (
    <div>
            <OTPasscode onOk={AttemptApproveConfirmation} onCancel={handleCloseConfirm} visible={dialogOpen}></OTPasscode>
    </div>);
};

export default AuthorisationConfirmation;