import React from 'react';
import '../../../Styles/Shared/content-page.css';
import UserChart from './UserChart/UserChart.js';
import { Card } from "react-bootstrap";
import TableExpandableDefaultContent from '../../../Elements/Tables/TableExpandableDefaultContent.js';
import { DataGridPro, useGridApiRef, GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid-pro";
import { useLocation, useNavigate, Outlet, BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { useRef, useState } from 'react';
import moment from "moment";
import { useEffect } from "react";
import Row from 'react-bootstrap/Row';
import { Button } from "react-bootstrap";
import Col from 'react-bootstrap/Col';
import APIService from '../../../Util/APIService.js'
import Grid from '@mui/material/Grid';
import { setRedirectPreviousRoute, clearRedirectPreviousRoute } from '../../../Store/RedirectPreviousRouteSlice';
import { useSelector, useDispatch } from 'react-redux';

const ProfileContainer = () => {
    const dispatch = useDispatch();
    const [userProfile, setUserProfile] = useState({});
    //Settings
    const navigate = useNavigate();
    moment.locale('en-GB');

    useEffect(() => {
        async function GetUserProfile() {
            const result = await APIService.get("User/ReturnUserProfile").then(res => {
                // var userObject = JSON.parse(res.data.Value);
                var userObject = res.data;
                userObject.ChangePassword = userObject.ChangePassword ? 'Yes' : 'No';
                userObject.IsLocked = userObject.IsLocked ? 'Yes' : 'No';
                setUserProfile(userObject);
            })
                .catch(error => {
                    navigate('/ErrorPage', { state: error.message });
                });
        }

        GetUserProfile();

    }, []);

    const logout = () => {
        localStorage.removeItem("user-token");
        localStorage.removeItem("passedSSO");
        dispatch(setRedirectPreviousRoute("/"))
        navigate('/');
        window.location.reload(false);
    }


    return (
    <div>
        <div className="nav-action-bar">
            <Breadcrumbs aria-label="breadcrumb">
                    <Link
                        underline="none"  color="inherit" >
                        User Profile
                    </Link>
            </Breadcrumbs>

        </div>
        <Grid container>
            <Grid item lg={6}>
                    <Card className="" style={{ margin: "20px" }}>

                        <Card.Header>User Details</Card.Header>
                        <Row className="form-row pl-2 col-xl-12 default-content-padding">
                            <Col md={6} sm={12} className="form-group mb-0 col-sm-6 ">
                                <label className="col-form-label font-sm">User</label>
                                <div className="form-control-plaintext pt-0">
                                    <span className="preserveFormat">{userProfile.Name }</span>
                                </div>
                            </Col>
                            <Col md={6} sm={12} className="form-group mb-0 col-sm-6 ">
                                <label className="col-form-label font-sm">Is locked</label>
                                <div className="form-control-plaintext pt-0">
                                    <span className="preserveFormat">{userProfile.IsLocked}</span>
                                </div>
                            </Col>
                        </Row>
                    </Card>
                </Grid>
                <Grid item lg={6}>
                    <Card className="" style={{ margin: "20px" }}>

                        <Card.Header>Profile Actions</Card.Header>
                        <Row className="form-row pl-2 col-xl-12 default-content-padding">
                            <Col md={6} sm={12} className="form-group mb-0 col-sm-6 ">
                            </Col>
                            <Col md={6} sm={12} className="form-group mb-0 col-sm-6 ">
                                <div className="form-control-plaintext pt-0">
                                    <span className="preserveFormat">
                                        <Button onClick={logout} autoFocus className="float-right">
                                            Sign out
                                        </Button>
                                    </span>
                                </div>
                            </Col>
                        </Row>
                    </Card>
                </Grid>
                <Grid item lg={6}>
                    <Card className="" style={{ margin: "20px" }}>

                        <Card.Header>Support</Card.Header>
                        <Row className="form-row pl-2 col-xl-12 default-content-padding">
                            <Col md={6} sm={12} className="form-group mb-0 col-sm-6 ">
                                <label className="col-form-label font-sm">Support Email</label>
                                <div className="form-control-plaintext pt-0">
                                    <span className="preserveFormat"><a href={process.env.REACT_APP_SupportURL}>{process.env.REACT_APP_SupportName}</a></span>
                                </div>
                            </Col>
                        </Row>
                    </Card>
                </Grid>
                <Grid item lg={6}>
                    <Card className="" style={{ margin: "20px" }}>

                        <Card.Header>Password Summary</Card.Header>
                        <Row className="form-row pl-2 col-xl-12 default-content-padding">
                        <Col md={6} sm={12} className="form-group mb-0 col-sm-6 ">
                            <label className="col-form-label font-sm">Requires Password Change</label>
                            <div className="form-control-plaintext pt-0">
                                    <span className="preserveFormat">{userProfile.ChangePassword}</span>
                            </div>
                        </Col>
                        <Col md={6} sm={12} className="form-group mb-0 col-sm-6 ">
                            <label className="col-form-label font-sm">Session Timeout</label>
                            <div className="form-control-plaintext pt-0">
                                    <span className="preserveFormat">30 minutes</span>
                            </div>
                            </Col>
                            <Col md={6} sm={12} className="form-group mb-0 col-sm-6 ">
                                <label className="col-form-label font-sm">Contact</label>
                                <div className="form-control-plaintext pt-0">
                                    <span className="preserveFormat">Email</span>
                                </div>
                            </Col>
                            <Col md={6} sm={12} className="form-group mb-0 col-sm-6 ">
                                <label className="col-form-label font-sm">Password Renew Date</label>
                                <div className="form-control-plaintext pt-0">
                                    <span className="preserveFormat">Not Available</span>
                                </div>
                            </Col>
                        </Row>
                    </Card>
                </Grid>


        </Grid>
        </div>
    )
};
export default () => <ProfileContainer />;