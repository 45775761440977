
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import React, { useEffect, useState } from "react";
import styles from "./DetailPanel.module.css"

function DetailPanel(props) {
    const [show, setShow] = useState(true);
    function changeState() {
        setShow(!show);
    }

    function generateHTML() {
        const elements = [];
        if (props.data.detail != null) { 
        for (var i = 0; i < props.data.detail.length; i++) {
            {
                if (String(props.data.detail[i].value) == "line") {
                    elements.push(<Col md={12} sm={12} className="form-group mb-0 col-sm-6 ">
                        <div className="form-control-plaintext pt-0 seperatingLine">
                        </div>
                    </Col>)
                }
                else {
                    elements.push(<Col md={props.data.detail[i].colW} sm={12} className="form-group mb-0 col-sm-6 ">
                        <label className="col-form-label font-sm">{String(props.data.detail[i].label)}</label>
                        <div className="form-control-plaintext pt-0">
                            <span className="preserveFormat">{String(props.data.detail[i].value)}</span>
                        </div>
                    </Col>)
                }
            }
        }
        }
        return elements;
    }

    return (
        
        <div className="card">
            <div className={styles.detailHeader} onClick={changeState}>{props.data.title}</div>
            {show && <Row className="form-row pl-2 col-xl-12 default-content-padding">
                {generateHTML()}
            </Row>}
            </div>
    );
}

export default DetailPanel;