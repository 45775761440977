import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import './FileUploadAdvanced.css';
import PostAddIcon from '@mui/icons-material/PostAdd';

const FileUploadAdvanced = ({ onFileSelect, onClose }) => {
    const [selectedfile, SetSelectedFile] = useState([]);
    const [Files, SetFiles] = useState([]);


    const filesizes = (bytes, decimals = 2) => {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    const InputChange = (e) => {
        // --For Multiple File Input
        let images = [];
        //SetFiles(e.target.files);
        for (let i = 0; i < e.target.files.length; i++) {
            images.push((e.target.files[i]));
            let reader = new FileReader();
            let file = e.target.files[i];
            reader.onloadend = () => {
                SetSelectedFile((preValue) => {
                    return [
                        ...preValue,
                        {
                            id: Date.now(),
                            filename: e.target.files[i].name,
                            filetype: e.target.files[i].type,
                            fileimage: reader.result,
                            datetime: e.target.files[i].lastModifiedDate.toLocaleString('en-IN'),
                            filesize: filesizes(e.target.files[i].size)
                        }
                    ]
                });
            }
            if (e.target.files[i]) {
                reader.readAsDataURL(file);
            }
        }
    }


    const DeleteSelectFile = (id) => {
      /*  if (window.confirm("Are you sure you want to delete this file?")) {*/
            const result = selectedfile.filter((data) => data.id !== id);
            SetSelectedFile(result);
        //} else {
        //    // alert('No');
        //}

    }

    const handleClose = () => {
        onClose();
    };


    const FileUploadSubmit = async (e) => {
        e.preventDefault();

        // form reset on submit 

        if (selectedfile.length > 0) {
            SetSelectedFile([]);
            onFileSelect(selectedfile);
        }
    }


    const DeleteFile = async (id) => {
            const result = Files.filter((data) => data.id !== id);
            SetFiles(result);
    }

    return (

        <div className="fileupload-view">
            <div className="bc-buttons-box">
                <button onClick={handleClose} className="btn btn-primary file-upload-button">Cancel</button>
                <button onClick={FileUploadSubmit} disabled={selectedfile.length < 1} type="submit" className="btn btn-primary file-upload-button">Upload</button>
            </div>
            <div className="row justify-content-center m-0">
                <div className="col-md-8">
                    <div className="mt-5">
                        <div className="">
                            <div className="bc-data-box">
                                <div className="bc-modal-data-title">
                                    <div className="bc-data-title">
                                        <h6>File Upload</h6>
                                    </div>
                                </div>
                                <div>
                                    <div className="bc-file-upload">
                                        <div className="file-upload-box">
                                            <input type="file" id="fileupload" className="file-upload-input" onChange={InputChange} multiple />
                                            <span>Drag and drop or <span className="file-link">Choose your files</span></span>
                                        </div>
                                    </div>
                                    <div className="bc-attach-box mb-3">
                                        {
                                            selectedfile.map((data, index) => {
                                                const { id, filename, filetype, fileimage, datetime, filesize } = data;
                                                return (
                                                    <div className="file-atc-box" key={id}>
                                                        {
                                                            filename.match(/.(jpg|jpeg|png|gif|svg)$/i) ?
                                                                <div className="file-image"> <img src={fileimage} alt="" /></div> :
                                                                <div className="file-image"><PostAddIcon /></div>
                                                        }
                                                        <div className="file-detail">
                                                            <h6>{filename}</h6>
                                                            <p></p>
                                                            <p><span>Size : {filesize}</span><span className="ml-2">Modified Time : {datetime}</span></p>
                                                            <div className="file-actions">
                                                                <button type="button" className="file-action-btn" onClick={() => DeleteSelectFile(id)}>Delete</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                             
                                </div>
                                {Files.length > 0 ?
                                    <div className="bc-attach-box">
                                        <hr />
                                        {
                                            Files.map((data, index) => {
                                                const { id, filename, filetype, fileimage, datetime, filesize } = data;
                                                return (
                                                    <div className="file-atc-box" key={index}>
                                                        {
                                                            filename.match(/.(jpg|jpeg|png|gif|svg)$/i) ?
                                                                <div className="file-image"> <img src={fileimage} alt="" /></div> :
                                                                <div className="file-image"><PostAddIcon/></div>
                                                        }
                                                        <div className="file-detail">
                                                            <h6>{filename}</h6>
                                                            <p><span>Size : {filesize}</span><span className="ml-3">Modified Time : {datetime}</span></p>
                                                            <div className="file-actions">
                                                                <button className="file-action-btn" onClick={() => DeleteFile(id)}>Delete</button>
                                                                <a href={fileimage} className="file-action-btn" download={filename}>Download</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    : ''}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}


export default FileUploadAdvanced;