import React from 'react';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import './NotifyIcon.css'; // Import the CSS file

const ProcessingIcon = () => {
    return (
        <div>
            <NotificationsNoneOutlinedIcon className='Notify-icon'>
            </NotificationsNoneOutlinedIcon>
        </div>
    );
};

export default ProcessingIcon;