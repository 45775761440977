import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { Button } from "react-bootstrap";
import { useEffect } from "react";
import "./PDFViewer.css"
import ZoomInOutlinedIcon from '@mui/icons-material/ZoomInOutlined';
import ZoomOutOutlinedIcon from '@mui/icons-material/ZoomOutOutlined';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';


export default function SinglePage(props) {
    const [numPages, setNumPages] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(true);
    const [pageNumber, setPageNumber] = useState(1); //setting 1 to show fisrt page
    const [scale, setScale] = useState(1);

    useEffect(() => {
        const handleResize = () => {
            const newScale = window.innerWidth / 1000; // Adjust the 800 value to fit your PDF's original width
            setScale(newScale);
        };

        handleResize(); // Initial scale calculation
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    function zoomIn() {
        setScale(scale + 0.25)
    }

    function closeDialog() {
        props.onCancel();
    }

    function zoomOut() {
        setScale(scale - 0.25)
    }

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        setPageNumber(1);
    }

    function changePage(offset) {
        setPageNumber(prevPageNumber => prevPageNumber + offset);
    }

    function previousPage() {
        changePage(-1);
    }

    function nextPage() {
        changePage(1);
    }

    const { pdf } = props;

    //if need show pages
    //<p>
    //    Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
    //</p>

    return (
        <>

            <Dialog fullWidth={true} maxWidth={"md"} open={dialogOpen}>
                <button className="pdfCloseButton" type="button" onClick={closeDialog}>
                    <CloseIcon></CloseIcon>
                </button>
                <DialogTitle>PDF Viewer</DialogTitle>
                <DialogContent>
                    <embed
                        type="application/pdf"
                        src={pdf}
                        width="100%"
                        height="650px"
                    />
                    </DialogContent>
            </Dialog>
        </>
    );
}
