import axios from "axios";
import { useNavigate, Outlet, BrowserRouter as Router, Routes, Route } from "react-router-dom";

const instance = axios.create({
    baseURL: process.env.REACT_APP_APIBaseURL ,
    timeout: 50000,
    headers: {
        'Content-Type': 'application/json',
    }
});

instance.interceptors.request.use(
    config => {
        const token = localStorage.getItem('user-token');
        if (token) {
            config.headers.Authorization = `${token}`;
        }
        return config;
    },
    error => Promise.reject(error)
);

function tidyError(error) {
    if (error.response != null && (error.response.data != null && error.response.data != "")) {
        try {
            const firstLine = error.response.data.split('at ')[0]
            if (firstLine == null) {
                firstLine = error.response.data;
            }
            else {
                firstLine = firstLine.trim();
            }
            error.message = firstLine;
        }
        catch {
            error.message = error.response.data;
        }
    }
    return error;
}

const ApiService = {

    get(url) {
        return instance.get(process.env.REACT_APP_APIName + url)
            .then(res => {
                return res
            })
            .catch(error => {
                return Promise.reject(tidyError(error));

            });
    },

    post(url, data) {
        return instance.post(process.env.REACT_APP_APIName + url, data)
            .then(res => {
                return res
            })
            .catch(error => {
                return Promise.reject(tidyError(error));

            });
    },

    awaitAll() {
        return axios.all(Array.from(arguments))
            .then(axios.spread((...responses) => responses))
            .catch(reasons => { console.log(reasons.response) });
    }

};

export default ApiService;