import React from 'react';
import '../../../Styles/Shared/content-page.css';
import { Card } from "react-bootstrap";
import TableExpandableDefaultContent from '../../../Elements/Tables/TableExpandableDefaultContent.js';
import { DataGridPro, useGridApiRef, GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid-pro";
import { useLocation, useNavigate, Outlet, BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { useRef, useState } from 'react';
import moment from "moment";
import { useEffect } from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import APIService from '../../../Util/APIService.js'
import Grid from '@mui/material/Grid';
function GeneralNotificationContainer(props) {
    //State declarations
    const [activityItems, setActivityItems] = useState([]);
    const [dataContainer, setDataContainer] = useState({ columns: [], rows: [] });
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [loading, setLoading] = useState(false);
    const [gridWidth, setGridWidth] = useState(window.innerWidth);
    const [columnList, setColumnList] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [payments, setPayments] = useState([]);
    const location = useLocation();

    //Settings
    const navigate = useNavigate();
    moment.locale('en-GB');
    const showColumns = windowWidth > 900;
    const showColumnsSm = windowWidth > 768;
    const apiRef = useGridApiRef();


    //Effect
    useEffect(() => {
        async function Activity() {
            setLoading(true);
            const result = await APIService.get("User/GetEvents").then(res => {

                var notifications = [];
                if (res != null) {
                    if (res.data != null) {
                        notifications = res.data
                        if (notifications != null && notifications.length > 0) {
                            setActivityItems(notifications);
                        }
                    }
                }
                setLoading(false);
            })
                .catch(error => {
                    navigate('/ErrorPage', { state: error.message });
                });
        }

        function handleResize() {
            setWindowWidth(window.innerWidth);
        }

        window.addEventListener('resize', handleResize);

        const columns = [
            { field: 'UserEventId', flex: 0, minWidth: 50 },
            { field: 'Description', headerName: 'Activity', flex: 2, minWidth: 50 },
      /*      { field: 'Event', headerName: 'Event', flex: 2, minWidth: 50 },*/
            { field: 'Timestamp', headerName: 'Date', type: 'date', flex: 2, minWidth: 50, valueFormatter: (params) => moment(params.value).format("DD/MM/YYYY") },
        ];

        setColumnList(columns);

        Activity();

        // remove listners to avoid data leak
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }


    // View
    return (
        <div>
            <div className="nav-action-bar">
                <Breadcrumbs aria-label="breadcrumb">
                    <Link
                        underline="none" color="inherit" >
                        General Notifications
                    </Link>
                </Breadcrumbs>

            </div>

            <Card className="list-page-padding">
                <Card.Body>
                    <div style={{ width: "100%" }}>
                        <DataGridPro
                            width="100%"
                            pageSizeOptions={[15, 25, 50, 100, 250]}
                            columnVisibilityModel={{
                                UserEventId: false,
                            }}
                            initialState={{
                                pagination: { ...dataContainer.initialState?.pagination, paginationModel: { pageSize: 25 } }

                            }}
                            getRowId={(row) => row.UserEventId}
                            autoHeight={true}
                            disableRowSelectionOnClick
                            density="comfortable"
                            loading={loading}
                            apiRef={apiRef}
                            slots={{
                                toolbar: CustomToolbar,
                            }}
                            disableColumnSelector={true}
                            pagination
                            {...gridWidth && { autoWidth: gridWidth }}
                            getRowHeight={() => 'auto'}
                            columns={columnList}
                            rows={activityItems} />
                    </div>
                </Card.Body>
            </Card>
        </div>
    )
}

export default GeneralNotificationContainer; 