
import React, { useRef, useState } from 'react';
import { createRoot } from 'react-router-dom';
import '../../Styles/Shared/content-page.css';
import styles from './Containers/Processing.module.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import GeneralProcessesContainer from './Containers/GeneralProcessesContainer';
import PaymentProcessesContainer from './Containers/PaymentProcessesContainer';
import Tabs from '@mui/material/Tabs';
import PropTypes from 'prop-types';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TabPanel from '../../Elements/Tabs/TabPanel'
import Badge from '@mui/material/Badge';
import Divider from '@mui/material/Divider';

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const Processing = () => {

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs variant="scrollable" scrollButtons="auto" value={value} onChange={handleChange} aria-label="basic tabs">
                    <Tab label="General" {...a11yProps(0)} />
                   
                  
                    <Tab label="Payment" {...a11yProps(1)} />

                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                <GeneralProcessesContainer />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <PaymentProcessesContainer />
            </TabPanel>  
        </Box>
    )
}
export default Processing;
