import React, { Component } from "react";
import Chart from "react-apexcharts";
import { Button, Card } from "react-bootstrap";

class UserChart extends Component {
    constructor(props) {
        super(props);

        this.state = {
            options: {
                chart: {
                    id: "area-chart",
                    animations: {
                        speed: 250, //make the transition faster
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                colors: ["#bf0d3e"],
                xaxis: {
                    categories: props.data.categories
                },

            },
            series: [
                {
                    name: props.data.series[0].seriesName,
                    data: props.data.series[0].seriesData
                }
            ]
            ,
            title: props.data.title
        };
    }

    render() {
        return (
            <Card className="" style={{ height: "290px" }}>

                <Card.Header>{this.state.title}</Card.Header>

                <div className="plugin-content">
                    <div className="row">
                        <div className="mixed-chart">
                            <Chart
                                options={this.state.options}
                                series={this.state.series}
                                type="area"
                                height="230px"
                            />
                        </div>
                    </div>
                </div>
            </Card>
        );
    }
}

export default UserChart;