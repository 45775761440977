import { createSlice } from '@reduxjs/toolkit';

const successMessageSlice = createSlice({
    name: 'successMessage',
    initialState: { successMessage: '' },
    reducers: {
        setSuccess(state, action) {
            state.successMessage = action.payload;
        },
        clearSuccess(state) {
            state.successMessage = '';
        },
    },
});

export const { setSuccess, clearSuccess } = successMessageSlice.actions;
export default successMessageSlice.reducer;