import { createSlice } from '@reduxjs/toolkit';

const errorMessageSlice = createSlice({
    name: 'errorMessage',
    initialState: { errorMessage: '' },
    reducers: {
        setError(state, action) {
            state.errorMessage = action.payload;
        },
        clearError(state) {
            state.errorMessage = '';
        },
    },
});

export const { setError, clearError } = errorMessageSlice.actions;
export default errorMessageSlice.reducer;