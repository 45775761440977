import React from 'react';
import BlurLinearIcon from '@mui/icons-material/BlurLinear';
import './FlashingIcon.css'; // Import the CSS file

const AnimatedProcessingIcon = () => {
    return (
        <div className="flashing-icon">
            <BlurLinearIcon>
            </BlurLinearIcon>
        </div>
    );
};

export default AnimatedProcessingIcon;