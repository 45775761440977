import React from 'react';
import '../../../Styles/Shared/content-page.css';
import styles from './UserProfileContainer.module.css';
import UserChart from './UserChart/UserChart.js';
import { Card } from "react-bootstrap";
import TableExpandableDefaultContent from '../../../Elements/Tables/TableExpandableDefaultContent.js';
import { DataGridPro, useGridApiRef, GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid-pro";
import { useLocation, useNavigate, Outlet, BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { useRef, useState } from 'react';
import moment from "moment";
import { useEffect } from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import APIService from '../../../Util/APIService.js'
import Grid from '@mui/material/Grid';
import { Button } from "react-bootstrap";
import BankClarityLogo from "../../../Assets/Images/VistraTheme/BankClarityBrand.png"
import { useSelector, useDispatch } from 'react-redux';
import { setSuccess, clearSuccess } from '../../../Store/SuccessMessageSlice';

const SettingsContainer = () => {
    const dispatch = useDispatch();
    const [userProfile, setUserProfile] = useState({});
    const [showEmailOTPAuth, setShowEmailOTPAuth] = useState(0);
    const [oTPBarcode, setOTPBarcode] = useState(BankClarityLogo);


    //Settings
    const navigate = useNavigate();
    moment.locale('en-GB');

    useEffect(() => {
        async function GetUserProfile() {
            const result = await APIService.get("User/ReturnUserProfile").then(res => {
                // var userObject = JSON.parse(res.data.Value);
                var userObject = res.data;
                userObject.ChangePassword = userObject.ChangePassword ? 'Yes' : 'No';
                userObject.IsLocked = userObject.IsLocked ? 'Yes' : 'No';
                setUserProfile(userObject);
                if (userObject.Settings != null) {
                    var hasSetting = false;
                    for (var i = 0; i < userObject.Settings.length; i++) {
                        if (userObject.Settings[i].Name == "OTPMode") {
                            setShowEmailOTPAuth(userObject.Settings[i].Value)
                            hasSetting = true;
                        }
                        if (userObject.Settings[i].Name == "OTPBarcodeUrl") {
                            setOTPBarcode("http://qrcode.kaywa.com/img.php?s=4&d=" + userObject.Settings[i].Value);
                        }
                    }

                    if (hasSetting == false) {
                        setShowEmailOTPAuth(0)
                    }
                }
                
            }).catch(error => {
                    navigate('/ErrorPage', { state: error.message });
                });
        }

        GetUserProfile();

    }, []);

async function toggleOTP() {
    var otpCounter = showEmailOTPAuth + 1;
    if (otpCounter > 2) {
        otpCounter = 0;
    }
    // skip authenticator app needs a way to get barcodeurl
    if (otpCounter == 1) {
        otpCounter = 2;
    }

        setShowEmailOTPAuth(otpCounter);
        const result = await APIService.post("User/ConfigureOTP", otpCounter).then(res => {

        })
        .catch(error => {
            console.log(error);
        });
    }

    async function refreshCache() {
       
        const result = await APIService.post("User/FlushCache").then(res => {
            dispatch(setSuccess("Cache Refreshed"))
        })
            .catch(error => {
                console.log(error);
            });
    }

    return (
        <div>
            <div className="nav-action-bar">
                <Breadcrumbs aria-label="breadcrumb">
                    <Link
                        underline="none" color="inherit" >
                        User Settings
                    </Link>
                </Breadcrumbs>

            </div>
            <Grid container>
                <Grid item lg={6}>
                    {showEmailOTPAuth == 2 &&
                        <Card className="" style={{ margin: "20px" }}>

                            <Card.Header>Two-factor authentification</Card.Header>
                            <Row className="form-row pl-2 col-xl-12 default-content-padding">
                                <Col md={12} sm={12} className="form-group mb-0 col-sm-6 ">
                                    <label className="col-form-label font-sm">Authentification type</label>
                                    <div className="form-control-plaintext pt-0">
                                        <span className="preserveFormat">Email</span>
                                    </div>
                                </Col>
                                <Col md={12} sm={12} className={"form-group mb-0 col-sm-6 " + styles.twoFactorInfo}>
                                    You will be emailed a passcode via email for 2 factor authentification on login
                                </Col>
                                <Col md={12} sm={12} className={"form-group mb-0 col-sm-6 " + styles.floatRight}>
                                    <Button onClick={toggleOTP} autoFocus className="float-right">
                                        Toggle
                                    </Button>
                                </Col>

                            </Row>
                        </Card>
                    }
                    {showEmailOTPAuth == 1 &&
                        <Card className="" style={{ margin: "20px" }}>

                            <Card.Header>Two-factor authentification</Card.Header>
                            <Row className="form-row pl-2 col-xl-12 default-content-padding">
                                <Col md={12} sm={12} className="form-group mb-0 col-sm-6 ">
                                    <label className="col-form-label font-sm">Authentification type</label>
                                    <div className="form-control-plaintext pt-0">
                                        <span className="preserveFormat">Authenticator App</span>
                                    </div>
                                </Col>
                                <Col md={12} sm={12} className={"form-group mb-0 col-sm-6 " + styles.twoFactorInfo}>
                                    You will be able to use app for authentification
                                </Col>
                                {oTPBarcode != "" &&
                                    <Col md={12} sm={12} className={"form-group mb-0 col-sm-6 " + styles.twoFactorInfo}>
                                        <a href={oTPBarcode}> Click for Authentication code </a>
                                    </Col>
                                }
                               
                                <Col md={12} sm={12} className={"form-group mb-0 col-sm-6 " + styles.floatRight}>
                                    <Button onClick={toggleOTP} autoFocus className="float-right">
                                        Toggle
                                    </Button>
                                </Col>

                            </Row>
                        </Card>
                    }
                    {showEmailOTPAuth == 0 &&
                        <Card className="" style={{ margin: "20px" }}>

                            <Card.Header>Two-factor authentification</Card.Header>
                            <Row className="form-row pl-2 col-xl-12 default-content-padding">
                                <Col md={12} sm={12} className="form-group mb-0 col-sm-6 ">
                                    <label className="col-form-label font-sm">Authentification type</label>
                                    <div className="form-control-plaintext pt-0">
                                        <span className="preserveFormat">None</span>
                                    </div>
                                </Col>
                                <Col md={12} sm={12} className={"form-group mb-0 col-sm-6 " + styles.twoFactorInfo}>
                                    No two factor authentification enabled
                                </Col>
                                <Col md={12} sm={12} className={"form-group mb-0 col-sm-6 " + styles.floatRight}>
                                    <Button onClick={toggleOTP} autoFocus className="float-right">
                                        Toggle
                                    </Button>
                                </Col>

                            </Row>
                        </Card>
                    }
                </Grid>
                <Grid item lg={6}>
                    <Card className="" style={{ margin: "20px" }}>

                        <Card.Header>Login time-out</Card.Header>
                        <Row className="form-row pl-2 col-xl-12 default-content-padding">
                            <Col md={12} sm={12} className="form-group mb-0 col-sm-6 ">
                                <label className="col-form-label font-sm">Current Time-out</label>
                                <div className="form-control-plaintext pt-0">
                                    <span className="preserveFormat">30 mins</span>
                                </div>
                            </Col>
                            <Col md={12} sm={12} className={"form-group mb-0 col-sm-6 " + styles.twoFactorInfo}>
                                You will be timed out after a period of 30 minutes
                            </Col>
                            <Col md={12} sm={12} className={"form-group mb-0 col-sm-6 " + styles.floatRight}>
                                <Button disabled autoFocus className={styles.floatRight}>
                                    Save
                                </Button>
                            </Col>

                        </Row>
                    </Card>
                </Grid>
                <Grid item lg={6}>
                    <Card className="" style={{ margin: "20px" }}>

                        <Card.Header>Cache refresh</Card.Header>
                        <Row className="form-row pl-2 col-xl-12 default-content-padding">
                            <Col md={12} sm={12} className="form-group mb-0 col-sm-6 ">
                                <label className="col-form-label font-sm">Refresh</label>
                                <div className="form-control-plaintext pt-0">
                                    <span className="preserveFormat"></span>
                                </div>
                            </Col>
                            <Col md={12} sm={12} className={"form-group mb-0 col-sm-6 " + styles.twoFactorInfo}>
                                This will refresh the Cache
                            </Col>
                            <Col md={12} sm={12} className={"form-group mb-0 col-sm-6 " + styles.floatRight}>
                                <Button onClick={refreshCache} autoFocus className={styles.floatRight}>
                                    Refresh
                                </Button>
                            </Col>

                        </Row>
                    </Card>
                </Grid>
            </Grid>
        </div>
    )
};
export default () => <SettingsContainer />;