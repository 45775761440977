import { createSlice } from '@reduxjs/toolkit';

const RedirectPreviousRouteSlice = createSlice({
    name: 'redirectPreviousRoute',
    initialState: { redirectPreviousRoute: '/' },
    reducers: {
        setRedirectPreviousRoute(state, action) {
            state.RedirectPreviousRoute = action.payload;
        },
        clearRedirectPreviousRoute(state) {
            state.RedirectPreviousRoute = '/';
        },
    },
});

export const { setRedirectPreviousRoute, clearRedirectPreviousRoute } = RedirectPreviousRouteSlice.actions;
export default RedirectPreviousRouteSlice.reducer;