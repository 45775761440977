/*Default Imports*/
import React, { useState, useEffect, useRef } from 'react';
import { Button, nav } from "react-bootstrap";
import { useNavigate, Outlet, BrowserRouter as Router, Routes, Route } from "react-router-dom";

/*Images import*/
import BankClarityIcon from "../Assets/Images/BC black.png"
import BankClaritySearch from "../Assets/Images/search.svg"
import BankClarityNotification from "../Assets/Images/approval.svg"
//import BankClarityLogo from "../Assets/Images/VistraTheme/BankClarityBrand.png"
import BankClarityLogo from "../Assets/Images/BankClarityBrand.png"
import PoweredByLogo from "../Assets/Images/Powered by BC white.png"
import ErrorBoundary from "../Util/ErrorBoundary";

import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInboxOutlined';
import MailIcon from '@mui/icons-material/MailOutlined';
import HomeIcon from '@mui/icons-material/HomeOutlined';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import PlaylistAddCheckCircleIcon from '@mui/icons-material/PlaylistAddCheckCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import FormatIndentDecreaseIcon from '@mui/icons-material/FormatIndentDecrease';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleRounded';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import AnimatedProcessingIcon from './ProcessingIcon/AnimatedProcessingIcon'
import AnimatedNotificationIcon from './NotificationIcon/AnimatedNotificationIcon'
import ProcessingIcon from './ProcessingIcon/ProcessingIcon'
import NotificationIcon from './NotificationIcon/NotificationIcon'

import './PortalNavbar.css';
import ProtectedRoute from '../Util/ProtectedRoute';
import APIService from '../Util/APIService.js'

/*Routing Page Imports*/
import Home from '../Views/Home/Home.js';
import { About } from '../Views/About/About.js';
import Profile from '../Views/Profile/UserProfile.jsx';
import BankAccountList from '../Views/Banking/Bank Account List/BankAccountList.jsx';
import BankStatement from '../Views/Banking/Bank Statement/BankStatement.jsx';
import PaymentList from '../Views/Payments/PaymentsList/PaymentsList.jsx';
import AuthorisationList from '../Views/Payments/AuthorisationList/AuthorisationList.jsx';
import PaymentView from '../Views/Payments/PaymentView/PaymentView.jsx';
import Notifications from '../Views/Notification/Notifications.jsx';
import Processing from '../Views/Processing/Processing.jsx';
import Login from '../Views/Login/login/Login';
import SignInOIDCCallback from '../Views/Login/SignInOIDCCallback';
import AuthorisationConfirmation from '../Views/Authorisation/AuthorisationConfirmation.jsx';
import ErrorPage from '../Views/Error/ErrorPage';
import { Link } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';
/*import { actions } from '../Store/index';*/
import { setError, clearError } from '../Store/ErrorMessageSlice';
import { setSuccess, clearSuccess } from '../Store/SuccessMessageSlice';
import { setWarning, clearWarning } from '../Store/WarningMessageSlice';
import { setInfo, clearInfo } from '../Store/InfoMessageSlice';
import { setRedirectPreviousRoute, clearRedirectPreviousRoute } from '../Store/RedirectPreviousRouteSlice';

const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));



const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

export default function PortalNavigation() {
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const [hideOnPortrait, setHideOnPortrait] = React.useState(window.innerWidth);
    const [drawerOpen, setDrawerOpen] = React.useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [hasNotifications, setHasNotifications] = useState(false);
    const [hasProcesses, setHasProcesses] = useState(false);
    const [alertOpen, setAlertOpen] = React.useState(true);
    const [tenantMessage, setTenantMessage] = React.useState("");
/*    const [errorMessage, setErrorMessage] = useState(null);*/

    const dispatch = useDispatch();
    const messageError = useSelector((state) => state.errorMessage.errorMessage);
    const messageSuccess = useSelector((state) => state.successMessage.successMessage);
    const messageWarning = useSelector((state) => state.warningMessage.warningMessage);
    const messageInfo = useSelector((state) => state.infoMessage.infoMessage)

    const fetchNotifications = async () => {
        try {
            // Make an API call to check for notification
            const result = await APIService.get("User/GetEvents").then(res => {

                var notifications = [];
                if (res != null) {
                    if (res.data != null) {
                        notifications = res.data
                        if (notifications != null && notifications.length > 0) {
                            setHasNotifications(notifications);
                        }
                    }
                }
            })
            .catch(error => {
                 navigate('/ErrorPage', { state: error.message });
            });

            const result2 = await APIService.get("Payments/ProcessingBatches").then(res => {

                var processes = [];
                if (res != null) {
                    if (res.data != null) {
                        processes = res.data
                        if (processes != null && processes.length > 0) {
                            setHasProcesses(processes);
                        }
                    }
                }
            })
                .catch(error => {
                   /* navigate('/ErrorPage', { state: error.message });*/
                });

        } catch (error) {
            console.error('Error fetching results:', error);
        }
    };


    const checkUserToken = () => {
        const userToken = localStorage.getItem('user-token');

        if (!userToken || userToken === 'undefined') {
            setIsLoggedIn(false);
        }
        else {

            setIsLoggedIn(true);
        }

    }

    const getEnvironmentInformation = async () => {
        try {
            // Make an API call to check for notification
            const result = await APIService.get("Configuration/GetEnvironmentInformation").then(res => {
                if (res.data != null) {
                    setTenantMessage(res.data.TenantMessage);
                }
            })
                .catch(error => {
                    navigate('/ErrorPage', { state: error.message });
                });

        } catch (error) {
            console.error('Error fetching environment:', error);
        }
    };

    useEffect(() => {
        checkUserToken();
        setHideOnPortrait(window.innerWidth);
        getEnvironmentInformation();

        const intervalId = setInterval(fetchNotifications, 60000);
        return () => clearInterval(intervalId);

    }, [isLoggedIn]);


    const navigate = useNavigate();
    function onClick(selected) {
        if (selected.key == "sign-out") {
            logout();
        }
        else {
            navigate('' + selected.key);
        }
    }

    const [openKeys, setOpenKeys] = useState(['sub1']);

    const logout = () => {
        localStorage.removeItem("user-token");
        localStorage.removeItem("passedSSO");
        dispatch(setRedirectPreviousRoute("/"))
        navigate('/');
        window.location.reload(false);
    }

    const handleDrawerOpen = () => {
        setHideOnPortrait(window.innerWidth);
        setDrawerOpen(true);
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setHideOnPortrait(window.innerWidth);
        setDrawerOpen(false);
        setOpen(false);
    };

    const showProcesses = () => {
        setDrawerOpen(false);
        setOpen(false);
        navigate('/Processing');
    };

    const showUserNotifications = () => {
        setDrawerOpen(false);
        setOpen(false);
        navigate('/Notifications');
    };

    const showUserProfile = () => {
        setDrawerOpen(false);
        setOpen(false);
        navigate('/UserProfile');
    };

    const handleAlertClose = () => {
        dispatch(setWarning(""))
        dispatch(setError(""))
        dispatch(setSuccess(""))
        dispatch(setInfo(""))   
        setAlertOpen(false);
    };

    //dashboard to add in when required
    //<ListItem button component={Link} to="/" onClick={handleDrawerClose}>
    //    <ListItemIcon>
    //        <HomeIcon />
    //    </ListItemIcon>
    //    <ListItemText primary="Dashboard" />
    //</ListItem>

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="fixed" className="appBar" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{
                            marginRight: 5,
                            ...(open && { display: 'none' }),
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <IconButton style={{ display: (drawerOpen ) ? 'block' : 'none' }}  onClick={handleDrawerClose} className="drawer-close">
                        <FormatIndentDecreaseIcon></FormatIndentDecreaseIcon>
                    </IconButton>
                    <Typography variant="h6" noWrap component="div">
                        <div className="BankClarityLogo"></div> 
                    </Typography>

                    <div className="tenant-message">
                        {tenantMessage}
                    </div>

                    <div style={{
                        float: 'right', position: 'absolute', right: '0px'
                    }}>
                        <div className="padding-right-nav hover-top-nav-icon" onClick={showProcesses}>
                            {hasProcesses ? (
                                <AnimatedProcessingIcon></AnimatedProcessingIcon>
                            ) : (
                                    <ProcessingIcon></ProcessingIcon>
                            )}

                        </div>
                        <div onClick={showUserNotifications} className="padding-right-nav hover-top-nav-icon">
                            {hasNotifications ? (
                                <AnimatedNotificationIcon></AnimatedNotificationIcon>
                            ) : (
                                    <NotificationIcon></NotificationIcon>
                            )}
                          
                        </div>
                        <div className="padding-right-nav hover-top-nav-icon">
                            <AccountCircleOutlinedIcon onClick={showUserProfile} className="NavTopIcon"></AccountCircleOutlinedIcon>
                        </div>
                        <div />
                    </div>
                </Toolbar>
            </AppBar>
            <Drawer style={{ display: (drawerOpen || (hideOnPortrait > 1100)) ? 'block' : 'none' }} transitionDuration={ 100 } variant="permanent" open={open} className="appDrawer" PaperProps={{
                sx: {
                    backgroundColor: "var(--colour-primary)",
                    color: "#c8b9bd",
                }
             
            }} >
                <DrawerHeader>
                    <IconButton onClick={handleDrawerClose} className="chevronNav">
                        {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                    
          
                </DrawerHeader>

                <Divider />

                <List>


                    <ListItem button component={Link} to="/" onClick={handleDrawerClose}>
                        <ListItemIcon>
                            <PlaylistAddCheckCircleIcon />
                        </ListItemIcon>
                        <ListItemText primary="Authorisation List" />
                    </ListItem>
                    <ListItem button component={Link} to="/PaymentList" onClick={handleDrawerClose}>
                        <ListItemIcon>
                            <ReceiptLongIcon />
                        </ListItemIcon>
                        <ListItemText primary="Payment List" />
                    </ListItem>
                    <ListItem button component={Link} to="/BankAccountList" onClick={handleDrawerClose}>
                        <ListItemIcon>
                            <AccountBalanceWalletIcon />
                        </ListItemIcon>
                        <ListItemText primary="Bank Accounts" />
                    </ListItem>
                    <ListItem button onClick={logout}>
                        <ListItemIcon>
                            <LogoutIcon />
                        </ListItemIcon>
                        <ListItemText primary="Sign Out" />
                    </ListItem>
                </List>
            </Drawer>
            {!drawerOpen && <img src={BankClarityIcon} className="bc-black-logo" width="auto"></img>}
            <Box component="main" sx={{ width: "100%", p: 3 }}>
                {messageError != null && messageError != ""  &&
                    <Snackbar open={messageError != null && messageError != ""} autoHideDuration={10000} onClose={handleAlertClose}>
                        <Alert onClose={handleAlertClose} severity="error" sx={{ width: '100%' }}>
                            {messageError}
                        </Alert>
                    </Snackbar>
                }
                {messageSuccess != null && messageSuccess != "" &&
                    <Snackbar open={messageSuccess != null && messageSuccess != ""} autoHideDuration={10000} onClose={handleAlertClose}>
                        <Alert onClose={handleAlertClose} severity="success" sx={{ width: '100%' }}>
                            {messageSuccess}
                        </Alert>
                    </Snackbar>
                }
                {messageInfo != null && messageInfo != "" &&
                    <Snackbar open={messageInfo != null && messageInfo != ""} autoHideDuration={10000} onClose={handleAlertClose}>
                        <Alert onClose={handleAlertClose} severity="info" sx={{ width: '100%' }}>
                            {messageInfo}
                        </Alert>
                    </Snackbar>
                }
                {messageWarning != null && messageWarning != "" &&
                    <Snackbar open={messageWarning != null && messageWarning != ""} autoHideDuration={10000} onClose={handleAlertClose}>
                        <Alert onClose={handleAlertClose} severity="warning" sx={{ width: '100%' }}>
                            {messageWarning}
                        </Alert>
                    </Snackbar>
                }

              
                <DrawerHeader />


                    <Routes>

                        
                        <Route path="/About" element={<About />}>
                            <Route path='' element={<ProtectedRoute> <About /> </ProtectedRoute>} />
                        </Route>
                        <Route path="/UserProfile" element={<Profile />}>
                            <Route path='' element={<ProtectedRoute> <Profile /> </ProtectedRoute>} />
                        </Route>
                        <Route path="/BankAccountList" element={<BankAccountList />}>
                            <Route path='' element={<ProtectedRoute> <BankAccountList /> </ProtectedRoute>} />
                        </Route>
                        <Route path="/BankStatement" element={<BankStatement />}>
                            <Route path='' element={<ProtectedRoute> <BankStatement /> </ProtectedRoute>} />
                        </Route>
                        <Route path="/PaymentList" element={<PaymentList />}>
                            <Route path='' element={<ProtectedRoute> <PaymentList /> </ProtectedRoute>} />
                        </Route>
                        <Route path="/" element={<AuthorisationList />}>
                            <Route path='' element={<ProtectedRoute> <AuthorisationList /> </ProtectedRoute>} />
                    </Route>
                    <Route path="/Notifications" element={<Notifications />}>
                        <Route path='' element={<ProtectedRoute> <Notifications /> </ProtectedRoute>} />
                    </Route>
                    <Route path="/Processing" element={<Processing />}>
                        <Route path='' element={<ProtectedRoute> <Processing /> </ProtectedRoute>} />
                    </Route>
                    <Route path="/" element={<Home />}>
                        <Route path='' element={<ProtectedRoute>   <ErrorBoundary> <Home /> </ErrorBoundary> </ProtectedRoute>} />
                    </Route>
                        <Route path="/PaymentView" element={<PaymentView />}>
                            <Route path='' element={<ProtectedRoute> <ErrorBoundary> <PaymentView /> </ErrorBoundary> </ProtectedRoute>} />
                    </Route>
                        <Route path="/ErrorPage" element={<ErrorPage />}>
                            <Route path='' element={<ProtectedRoute> <ErrorBoundary> <ErrorPage /> </ErrorBoundary> </ProtectedRoute>} />
                    </Route>
                    <Route path="/signin-oidc" element={<SignInOIDCCallback />}>
                        <Route path='' element={<ProtectedRoute> <ErrorBoundary> <SignInOIDCCallback /> </ErrorBoundary> </ProtectedRoute>} />
                    </Route>
                    <Route path="/AuthorisationConfirmation" element={<AuthorisationConfirmation />}>
                        <Route path='' element={<ProtectedRoute> <ErrorBoundary> <AuthorisationConfirmation /> </ErrorBoundary> </ProtectedRoute>} />
                    </Route>


                    </Routes>
            </Box>
        </Box>
    );
}