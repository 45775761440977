import { configureStore, createSlice, combineReducers } from '@reduxjs/toolkit'
import errorMessageReducer from './ErrorMessageSlice';
import infoMessageReducer from './InfoMessageSlice';
import successMessageReducer from './SuccessMessageSlice';
import warningMessageReducer from './WarningMessageSlice';
import redirectPreviousRouteReducer from './RedirectPreviousRouteSlice';

const rootReducer = combineReducers({
    errorMessage: errorMessageReducer,
    successMessage: successMessageReducer,
    warningMessage: warningMessageReducer,
    infoMessage: infoMessageReducer,
    redirectPreviousRoute: redirectPreviousRouteReducer
});

export const store = configureStore({
    reducer: rootReducer,
});