import React, { Component } from "react";
import Chart from "react-apexcharts";

class BarChart extends Component {
    constructor(props) {
        super(props);

        this.state = {
            options: {
                chart: {
                    id: "bar",
                    animations: {
                        speed: 250, //make the transition faster
                    }
                },
                xaxis: {
                    categories: props.data.categories
                    //[1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998]
                },
                  title: {
                      text: props.data.title,
                    align: 'left'
                },
               
            },
            series: [
                {
                    name: props.data.series[0].seriesName,
                    data: props.data.series[0].seriesData
                }
            ]
        };
    }

    render() {
        return (
            <div className="app">
                <div className="row">
                    <div className="mixed-chart">
                        <Chart
                            options={this.state.options}
                            series={this.state.series}
                            type="bar"
                      
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default BarChart;