
import React, { useRef, useState } from 'react';
import './Home.css';
import { createRoot } from 'react-router-dom';
import '../../Styles/Shared/content-page.css';
import BarChart from '../../Elements/Charting/BarChart.js';
import AreaChart from '../../Elements/Charting/AreaChart.js';
import LineChart from '../../Elements/Charting/LineChart.js';
import PieChart from '../../Elements/Charting/PieChart.js';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import DashboardContainer from './Containers/DashboardContainer';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TabPanel from '../../Elements/Tabs/TabPanel'

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

    export default function BasicTabs() {
        const [value, setValue] = React.useState(0);

        const handleChange = (event, newValue) => {
            setValue(newValue);
        };

        return (
            <Box sx={{ width: '100%', padding:'0px' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Summary" {...a11yProps(0)} />
                        {/*<Tab label="Item Two" {...a11yProps(1)} />*/}
                        {/*<Tab label="Item Three" {...a11yProps(2)} />*/}
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    <DashboardContainer/>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    Item Two
                </TabPanel>
                <TabPanel value={value} index={2}>
                    Item Three
                </TabPanel>
            </Box>
        );
    }
