import { UserManager } from 'oidc-client-ts';

const config = {
    authority: process.env.REACT_APP_oidcAuthority,
    client_id: process.env.REACT_APP_oidcClientID,
    redirect_uri: process.env.REACT_APP_oidcRedirect,
    response_type: 'code',
    scope: 'openid',
    automaticSilentRenew: true,
    filterProtocolClaims: true,
    loadUserInfo: false,
};

const userManager = new UserManager(config);

export default userManager;