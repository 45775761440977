import React, { Component } from "react";
import Chart from "react-apexcharts";

class AreaChart extends Component {
    constructor(props) {
        super(props);

        this.state = {
            options: {
                chart: {
                    id: "area-chart",
                     animations: {
                        speed: 250, //make the transition faster
                    }
                },
                xaxis: {
                    categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998]
                },
                title: {
                    text: 'Fundamental Analysis of money',
                    align: 'left'
                },
               
            },
            series: [
                {
                    name: "series-1",
                    data: [30, 40, 45, 50, 49, 60, 70, 91]
                }
            ]
        };
    }

    render() {
        return (
            <div className="app">
                <div className="row">
                    <div className="mixed-chart">
                        <Chart
                            options={this.state.options}
                            series={this.state.series}
                            type="area"
                      
    
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default AreaChart;