import './TableStyles.css';
import { useNavigate, Outlet, BrowserRouter as Router, Routes, Route } from "react-router-dom";


function TableExpandableButtonRow(props) {

    const navigate = useNavigate();
    function TableButtonClick(selected, route) {
        navigate('' + route, { state: { itemID: props.data.IDContainer } });
    }


    return (
        <div className="table-button-bar">
            {props.data.dataContainer.map((value, index) => {
                return <button key={index} className="table-button-group-button mt-2" onClick={() => TableButtonClick(props.data.IDContainer, value.route) }>{value.description}</button>
            })}
        </div>
    );
}

export default TableExpandableButtonRow;