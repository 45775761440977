import React from 'react';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import './NotifyIcon.css'; // Import the CSS file

const AnimatedProcessingIcon = () => {
    return (
        <div className="Notify-icon-animate">
            <NotificationsActiveOutlinedIcon className='Notify-icon'>
            </NotificationsActiveOutlinedIcon>
        </div>
    );
};

export default AnimatedProcessingIcon;