import * as React from 'react';
import { Button } from "react-bootstrap";
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import DialogContent from '@mui/material/DialogContent';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import styles from "./AuthorisationList.module.css"
import "./Overrides.css"
import DeleteIcon from '@mui/icons-material/Delete';
import { DataGridPro } from "@mui/x-data-grid-pro";
import CircularProgress from '@mui/material/CircularProgress';


const ItemProcessProgressDialog = ({ open, onClose, onConfirm, onDeleteEntry, title, content, processing }) => {
    const [loading, setLoading] = React.useState(false);
    const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
    const handleDelete = (paymentID) => {
        // You can use onDeleteEntry function here, and pass any required parameters
        onDeleteEntry(paymentID);
    };

    //Settings
    const showColumns = windowWidth > 900;
    const showColumnsSm = windowWidth > 768;

    function handleResize() {
        setWindowWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);

    const columns = [
        { field: 'Client', headerName: 'Payer', flex: 3, minWidth: 75 },
        { field: 'PayeeName', headerName: 'Payee', flex: 3, minWidth: 75 },
        { field: 'Description', headerName: 'Description', flex: 3, minWidth: 75 },
        { field: 'PaymentAmount', headerName: 'Amount', flex: 3, minWidth: 75, valueFormatter: (params) => Number(params.value).toLocaleString(process.env.REACT_APP_CurrencyFormat) } ,
        { field: 'PaymentCurrencyIsoCode', headerName: 'CCY', flex: 3, minWidth: 75 },
        {
            field: 'Id',
            headerName: '',
            renderCell: (params) => {
                if (params.row.Success && params.row.Progress == 100)
                { return (<CheckCircleOutlineOutlinedIcon />); }
                else if (!params.row.Success && params.row.Progress == 0)
                { return (<CancelOutlinedIcon />); }
                else if (params.row.Progress > 10 && params.row.Progress < 100)
                { return (<CircularProgress size={20} />); }
                else
                { return (<DeleteIcon onClick={() => handleDelete(params.value)}></DeleteIcon>); }
            },
            flex: 1, minWidth: 75
        },
        // Add more columns as needed
    ];

        return (
            <Dialog
                    fullScreen
                    open={open}
                    onClose={onClose}
                sx={{
                    height: 'auto !important',
                    zIndex: "1000 !important"
                }}
            >
                <AppBar sx={{ position: 'relative' }} className={styles.processDialogAppbar}>
                    <Toolbar className={styles.processDialogToolbar}>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={onClose}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>

                        <Button disabled={processing}  className={styles.authorisationProcessButton} variant="success" onClick={onConfirm}>
                            Approve
                        </Button>

                        </Toolbar>
                </AppBar>
                <div className={styles.authorisationTitle}>{title}</div>
                <div className={styles.tablePadding}>
                    <DataGridPro 
                        rows={content}
                        columnVisibilityModel={{
                            QuestionnaireId: true,
                            Client: showColumns,
                            PaymentReasonDescription: true,
                            PayeeName: showColumns,
                            PaymentAmount: true,
                            PaymentCurrencyIsoCode: showColumnsSm,
                        }}
                        autoHeight 
                    columns={columns}
                    getRowId={(row) => row.Id}
                        loading={loading}

                    />
                </div>
                </Dialog>
        );
    };

export default ItemProcessProgressDialog;