import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import PortalNavigation from "./Portal/PortalNavigation";
import ProtectedRoute from './Util/ProtectedRoute';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from './Views/Login/login/Login';
import ErrorBoundary from "./Util/ErrorBoundary";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

//import './Styles/Themes/VistraTheme.css';
//import './Styles/Themes/${process.env.REACT_APP_FolderTheme}/${process.env.REACT_APP_FolderTheme}.css';

import { LicenseInfo } from '@mui/x-license-pro';
import SignInOIDCCallback from './Views/Login/SignInOIDCCallback';
import ResetConfirm from './Views/Login/PasswordReset/ResetPasswordConfirm';
import SendResetRequest from './Views/Login/PasswordReset/SendResetRequest';

function App() {
    LicenseInfo.setLicenseKey('58d02a7f1dd8a2efc79a70c899d0131eTz04OTUzNyxFPTE3NDYxNzU3NzMwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [passedSSO, setPassedSSO] = useState(false);

    // Create a function to dynamically load CSS
    function loadThemeCss() {
        import('./Styles/' + process.env.REACT_APP_FolderTheme + '/' + process.env.REACT_APP_FolderTheme + '.css');
    }

    // Use the function with your environment variable
    

    const checkUserToken = () => {
        const userToken = localStorage.getItem('user-token');
        const passedSSO = localStorage.getItem('passedSSO');

        if (!passedSSO || passedSSO === 'undefined') {
            setPassedSSO(false);
        }
        else {

            setPassedSSO(true);
        }


        if (!userToken || userToken === 'undefined') {
            setIsLoggedIn(false);
        }
        else {

            setIsLoggedIn(true);
        }

    }

    useEffect(() => {
        loadThemeCss();
        checkUserToken();
    }, [isLoggedIn]);

    return (
        <React.Fragment>

            <BrowserRouter basename={'/'}>
                <Routes>
                    <Route path="/signin-oidc" element={<SignInOIDCCallback />} />
                    <Route path="/sendresetrequest" element={<SendResetRequest />} />
                    <Route path="/resetpassword" element={<ResetConfirm />} />
                    {(!isLoggedIn) && <Route path="/" element={<Login />} />}
                </Routes>
                <ErrorBoundary>
                    
                        {(isLoggedIn) && <PortalNavigation />}
                    
                </ErrorBoundary>
{/*                {(!isLoggedIn) && <Login />}*/}
      
            </BrowserRouter>


        </React.Fragment>
    );
}

export default App;
